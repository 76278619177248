import {
  FormControl,
  FormLabel,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import DoubleLeft from "../../images/double-left-arrow.png";
import DoubleRigth from "../../images/double-rigth-arrow.png";

import {
  Button,
  Input,
  Pagination,
  PaginationProps,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { getControleProcessoContrato } from "../../api/api";
import { reduceText, rowClassName } from "../../util/util";
import { contratoAtom } from "../../atom/formFiltroPrincipal";
import { useRecoilState } from "recoil";
import { SearchOutlined } from '@ant-design/icons';
import type {
  ColumnsType,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/es/table/interface";

const { Title } = Typography;
const { Search } = Input;
interface DataType {
  contrato: string;
  razao_social: string;
  cidade: string;
  id_controle_processos: string;
  baixar: string;
  enviar_erro_log: string;
  enviar_conciliacao: string;
  enviar_rejeicao_fiscal: string;
  receber_msgs: string;
  enviar_boletos: string;
  status: string;
  cobranca: string;
  tipo_atualizacao: string;
}
export const ControleProcessoContrato = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [controleAtualizacao, setControleAtualizacao] = useState<DataType[]>(
    []
  );
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const navigate = useNavigate();
  const [totalItens, setTotalItens] = useState(0);
  const [currentPosition, setPosition] = useState<number>(1);
  const [limite, setLimite] = useState(20);
  const [loading, setLoading] = useState(false);

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});

  const [textContrato, setTextContrato] = useRecoilState(contratoAtom);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    setLoading(true);
    getControleProcessoContrato(
      pagination?.current,
      pagination?.pageSize,
      textContrato
    ).then((result) => {
      setTotalItens(result?.data?.total);
      setControleAtualizacao(result?.data?.lista);
      setLoading(false);
    });
  }, [pagination?.current, pagination?.pageSize, textContrato]);

  const next = (numero: number) => {
    setPosition(currentPosition + numero);
  };
  const handleFIlter = () => {
    setPosition(1);
  };

  // @ts-ignore
  const handleChange: TableProps<DataType>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<DataType> | SorterResult<DataType>[]
  ) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<DataType>);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Contrato",
      dataIndex: "contrato",
      key: "contrato",
      fixed: "left",
      showSorterTooltip: false,
      sorter: (a:DataType, b:DataType) => Number(a.contrato) - Number(b.contrato),
      sortOrder: sortedInfo.columnKey === 'contrato' ? sortedInfo.order : null,
    },
    {
      title: "Razão social",
      dataIndex: "razao_social",
      key: "razao_social",
      width: "400px",
      fixed: "left",
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => record.toString().includes(value.toString()),
      sorter: (a, b) => {
        const razaoA = a.razao_social || ""; // Se for nulo, trate como uma string vazia
        const razaoB = b.razao_social || ""; // Se for nulo, trate como uma string vazia
        return razaoA.localeCompare(razaoB);
      },
      sortOrder: sortedInfo.columnKey === 'razao_social' ? sortedInfo.order : null,
      showSorterTooltip: false,
      render: (text: string) => {
        return (
          <>
            <Tooltip title={text}>
              <a>{reduceText(text, 30)}</a>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Cidade",
      dataIndex: "cidade",
      key: "cidade",
    },
    {
      title: "Baixar",
      dataIndex: "baixar",
      key: "baixar",
      render: (text: string) => {
        let texto = "";
        let color = "";
        if (text === "Sim") {
          texto = "Sim";
          color = "#87d068";
        } else {
          texto = "Não";
          color = "#d07268";
        }
        return <>{texto}</>;
      },
    },
    {
      title: "Enviar Erro Log",
      dataIndex: "enviar_erro_log",
      key: "enviar_erro_log",
      render: (text: string) => {
        let texto = "";
        let color = "";
        if (text === "Sim") {
          texto = "Sim";
          color = "green";
        } else {
          texto = "Não";
          color = "red";
        }
        return <>{texto}</>;
      },
    },
    {
      title: "Enviar Conciliação",
      dataIndex: "enviar_conciliacao",
      key: "enviar_conciliacao",
      render: (text: string) => {
        let texto = "";
        let color = "";
        if (text === "Sim") {
          texto = "Sim";
          color = "green";
        } else {
          texto = "Não";
          color = "red";
        }
        return <>{texto}</>;
      },
    },
    {
      title: "Enviar Rejeição Fiscal",
      dataIndex: "enviar_rejeicao_fiscal",
      key: "enviar_rejeicao_fiscal",
      render: (text: string) => {
        let texto = "";
        let color = "";
        if (text === "Sim") {
          texto = "Sim";
          color = "green";
        } else {
          texto = "Não";
          color = "red";
        }
        return <>{texto}</>;
      },
    },
    {
      title: "Receber Mensagem",
      dataIndex: "receber_msgs",
      key: "receber_msgs",
      render: (text: string) => {
        let texto = "";
        let color = "";
        if (text === "Sim") {
          texto = "Sim";
          color = "green";
        } else {
          texto = "Não";
          color = "red";
        }
        return <>{texto}</>;
      },
    },
    {
      title: "Enviar Boletos",
      dataIndex: "enviar_boletos",
      key: "enviar_boletos",
      render: (text: string) => {
        let texto = "";
        let color = "";
        if (text === "Sim") {
          texto = "Sim";
          color = "green";
        } else {
          texto = "Não";
          color = "red";
        }
        return <>{texto}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => {
        let texto = "";
        let color = "";
        if (text === "Sim") {
          texto = "Sim";
          color = "green";
        } else {
          texto = "Não";
          color = "red";
        }
        return <>{texto}</>;
      },
    },
    {
      title: "Cobrança",
      dataIndex: "cobranca",
      key: "cobranca",
      render: (text: string) => {
        let texto = "";
        let color = "";
        if (text === "Sim") {
          texto = "Sim";
          color = "green";
        } else {
          texto = "Não";
          color = "red";
        }
        return <>{texto}</>;
      },
    },
    {
      title: "Tipo Atualização",
      dataIndex: "tipo_atualizacao",
      key: "tipo_atualizacao",
      fixed: "right",
      render: (text: string) => {
        let texto = "";
        let color = "";
        if (text === "Automático") {
          texto = "Automático";
          color = "#87d068";
        } else {
          texto = "Manual";
          color = "#d07268";
        }
        return (
          <Tag style={{ width: "100px", textAlign: "center" }} color={color}>
            {texto}
          </Tag>
        );
      },
    },
  ];

  return (
    <>
      <VStack w={"full"} bg={"#F7FAFC"}>
        <HStack spacing={5} w="full"></HStack>
        <VStack bgColor={"#F7FAFC"} borderRadius={5} minH={400} w={"full"}>
          <VStack pr={5} alignItems={"start"} w="full">
            <Title level={3}>Controle de Processo por Contrato</Title>
          </VStack>
          <HStack w={"full"} alignContent={"start"}>
            <Space.Compact style={{ width: "300px" }}>
              <Input addonAfter={<SearchOutlined />}
                allowClear
                onChange={(e) => {
                  setTextContrato(e.target.value);
                }}
                placeholder="Contrato ou Razão Social"
              />
            </Space.Compact>
          </HStack>
          <Table
            onChange={handleChange}
            scroll={{ x: 1700 }}
            rowClassName={rowClassName}
            loading={loading}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  navigate(record?.contrato);
                },
              };
            }}
            size="small"
            style={{
              width: "100%",
              overflowX: "scroll",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
            pagination={false}
            columns={columns}
            dataSource={controleAtualizacao}
          />
        </VStack>
        <VStack my={4}>
          <Pagination
            total={totalItens}
            showTotal={(totalItens) => `Total de ${totalItens} itens`}
            defaultPageSize={pagination?.pageSize}
            defaultCurrent={1}
            onChange={(page, pageSize) => {
              setPagination({
                current: page,
                pageSize: pageSize,
              });
            }}
            current={pagination?.current}
            locale={{ items_per_page: "/ Página"}}
          />
        </VStack>
      </VStack>
      <Modal
        size={"lg"}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Criar versão de produção</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack overflow={"auto"} maxH={300}>
              <FormControl>
                <FormLabel>Título</FormLabel>
                <Input ref={initialRef} placeholder="Versão x-yz" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Versão</FormLabel>
                <Input placeholder="Last name" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Descrição</FormLabel>
                <Input placeholder="Last name" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Nome do arquivo</FormLabel>
                <Input placeholder="Last name" />
              </FormControl>
              <FormControl>
                <FormLabel>Descrição</FormLabel>
                <Textarea placeholder="Descreva a versão" size="sm" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>
                  Por favor, digite "eu confirmo que desejo lançar essa versão"
                </FormLabel>
                <Input placeholder="" />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button>Lançar</Button>
            <Button onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
