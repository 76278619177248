import moment from 'moment'
export function stringToDate(date: string) {

    const currentDate = moment(date).format('ll');
    return currentDate;
  }
  export function stringToDateWithHour(date: string) {

    const currentDate = moment(date).format("DD/MM/YYYY");;
    return currentDate;
  }
  
  export function reduceText(text:string, maxLength:number) {
    const value = text ?? "";
    if (value.length <= maxLength) {
      return value;
    }
    return value.substring(0, maxLength) + '...';
  }
  export const copyToClipboard = (text:string) => {
    navigator.clipboard.writeText(text)
      .then(() => console.log(`Copied "${text}" to clipboard`))
      .catch((error) => console.error(`Error copying "${text}" to clipboard:`, error));
  };

  export function formatBytes(bytes : number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  export const rowClassName = (record: any, index: number) => {
    if (index % 2 === 0) {
      return 'table-row-light';
    } else {
      return 'table-row-dark';
    }
  };
  export const retornaCorDocumento = (documento: string) => {
    let cor = '';
    if(documento === 'NFE'){
      cor = '#87d068'
    }
    if(documento === 'NFCE'){
      cor = '#FDC835'
    }
    if(documento === 'CTE'){
      cor = 'brown'
    }
    if(documento === 'MDFE'){
      cor = '#4F6367'
    }
    return cor;
  }

  export const intToBoolean = (x : number) => {
    return x >= 1 ? true : false;
  }
  export const isMatriz = (praca : string) => {
    return (praca === 'Matriz')
  }

export const eMatriz = (praca: string)  => {
  return (praca === 'MATRIZ')
}