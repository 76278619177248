import { HStack, Image, VStack, Button as ButtonChakra, Text as TextChakra } from "@chakra-ui/react";
import {
  Button,
  Drawer,
  Empty,
  Form,
  Pagination,
  PaginationProps,
  Progress,
  Select as SelectAnt,
  Space,
  Table,
  Typography
} from "antd";
import {AiOutlineCloudDownload} from 'react-icons/ai'
import type { ColumnsType } from 'antd/es/table';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DropzoneRootProps, useDropzone } from "react-dropzone";
import Upload from "../../img/opcao-de-upload-de-armazenamento-em-nuvem.png";
import { UseMessageApi } from "../../message/UseMessageApi";
import { formatBytes, rowClassName, stringToDate, stringToDateWithHour } from "../../util/util";
import "../versoes/versoes.css";
import { LoadingSpin } from "./Loading";
import { getErros } from "../../api/api";
import dayjs, { Dayjs } from "dayjs";
import moment, { Moment } from "moment";
const { Text: Text, Title, Link } = Typography;
const { Option } = SelectAnt;
interface DataType {
	contrato: string,
	id: 18,
	titulo: string,
	detalhe: string,
	versao: string,
	data_ocorrencia: string,
	programa: string,
	os: string
}

export const useDrawerModalErrosLogs = (
  setFetch:React.Dispatch<React.SetStateAction<boolean>>,
  fetch: boolean,
  openDrawer: boolean,
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [form] = Form.useForm();

  const [descricao, setDescricao] = useState("");
  const [nomeArquivo, setNomeArquivo] = useState("jjhd.php");
  const [statusExecucao, setStatusExecucao] = useState("0");
  const [codigo, setCodigo] = useState("dddd");
  const [tempoMedioAtualizacao, setTempoMedioAtualizacao] = useState(0);

  const [dataInicial, setDataInicial] = useState<Moment | null>(moment().subtract(640, 'd')); // Use Moment type for state
  const [dataFinal, setDataFinal] = useState<Moment | null>(moment());



  const [loading, setLoading] = useState(false);
  const [limite, setLimite] = useState(5);
  const [currentPosition, setPosition] = useState<number>(1);
  const [totalItens, setTotalItens] = useState(0);
  //messages
  const { contextHolder, error, success, warning } = UseMessageApi();
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [file, setFile] = useState<any>(null);
  const [type, setType] = useState<string>();
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [loadingErrosLog, setLoadingErrosLog] = useState();
  const [errosLog, setErroLog] = useState<DataType[]>([]);
  const onDrop = (acceptedFiles: any) => {
    setFileName(acceptedFiles[0].name);
    setFileSize(acceptedFiles[0].size);
    setType(acceptedFiles[0].type);
    setFile(acceptedFiles[0]);
    setShowProgress(false);
  };

  const { getRootProps, getInputProps, isDragActive }: DropzoneRootProps =
    useDropzone({ onDrop });


    
    useEffect(() => {
      getErros(currentPosition, limite, dataInicial ? dataInicial : null, dataFinal ? dataFinal : null, "",null, null, "", "", "").then((result) => {
        setErroLog(result?.data?.erros)
        //setErrosNaSemana(result?.data?.total)
        setTotalItens(result?.data?.total)
      }).then((error) => {
  
      })
     // getDias(dataInical?.format("DD-MM-YYYY") ?? "", dataFinal?.format("DD-MM-YYYY") ?? "").then((result) => {
       // setDiasSemana(result?.data)
        // diasSemana.reverse();
  
      //  console.warn(result)
   //   }).then((error) => {
  
    //  })
    }, [currentPosition, limite, dataFinal, dataInicial]);


  const showDrawerCriarModal = () => {
    setOpenDrawer(true);
  };
  const onCloseDrawerModal = () => {
    setOpenDrawer(false);
  };
  useEffect(() => {}, []);

  const handleUpload = () => {
    if (file) {
      setCodigo("e");
    }
  };
  const columns: ColumnsType<DataType> = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Contrato',
			dataIndex: 'contrato',
			key: 'contrato',
		},
		{
			title: 'Código da Versão',
			dataIndex: 'cod_versao',
			key: 'cod_versao',
		},
		{
			title: 'Descrição Simplificada',
			dataIndex: 'desc_simplificada',
			key: 'desc_simplificada',
		},
		{
			title: 'Data ocorrência',
			dataIndex: 'data_ocorrencia',
			key: 'data_ocorrencia',
			render: (text) => {
				return <a>{stringToDateWithHour(text)}</a>
			}
		},
		{
			title: 'Programa',
			dataIndex: 'programa',
			key: 'programa',
		},
		{
			title: 'Ação',
			dataIndex: 'açao',
			key: 'açao',
      render: (text) => {
				return (
      <HStack>
          <AiOutlineCloudDownload cursor={"pointer"} color="#1677FF"/>
      </HStack>
        );
			}
		}
	]
  const onChange: PaginationProps["onChange"] = (page) => {
    console.log(page);
    setPosition(page);
  };
  const UploadComponenteReturn = (
    <VStack
      {...getRootProps()}
      cursor={"pointer"}
      w={"full"}
      bg={"#fafafa"}
      borderColor={"#f1f1f1"}
      borderRadius={5}
      borderWidth={"1.8px"}
      paddingY={5}
    >
      <Image width={"48px"} src={Upload} />
      <Text>Click aqui para selecionar um arquivo</Text>
      <Text type="secondary">
        {fileName
          ? `${fileName} ${formatBytes(Number(fileSize))}`
          : "Suporte para apenas 1 upload por vez"}
      </Text>
      {showProgress && (
        <Progress
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
          percent={progress}
          size="small"
        />
      )}
    </VStack>
  );
  const DrawerModal = (
    <>
      {contextHolder}
      <Drawer
      size="default"
        title="Erros de Log"
        width={"100%"}
        onClose={onCloseDrawerModal}
        open={openDrawer}
        bodyStyle={{ paddingBottom: 80, backgroundColor: '#F7FAFC' }}
        extra={
          <Space>
            <Button disabled={loading} onClick={onCloseDrawerModal}>Cancelar</Button>
            <Button loading={loading} type="primary" >
              Criar
            </Button>
          </Space>
        }
      >
  <VStack w={"full"} bg="#F7FAFC">
        <HStack w="full"></HStack>
        <VStack bgColor={"#F7FAFC"} height={"70vh"} borderRadius={0} w={"full"}>
          <VStack px={5}  alignItems={"start"} w="full">
            <Title level={3}>Razão social LTDA</Title>

            <HStack w={"full"}>
              <Text type="secondary">
                Crie e gerencie versões de produção para disponibilizar para
                todos os usuários.
              </Text>

            
            </HStack>
          </VStack>
          {loadingErrosLog ? (
            <LoadingSpin/>
          ) : (
            <>
              {!loadingErrosLog && errosLog?.length === 0 ? (
                <Empty
                  style={{ paddingTop: "40px" }}
                  description={"Lista vazia"}
                />
              ) : (
                <Table
                  size="small"
                  rowClassName={rowClassName}
                  pagination={false}
                  style={{ width: "100%" }}
                  dataSource={errosLog}
                  columns={columns}
                />
              )}
            </>
          )}

          {loadingErrosLog ? (
        <></>
          ) : (
            <>
              {!loadingErrosLog && errosLog?.length === 0 ? (
                <></>
              ) : (
                <Pagination
                  total={totalItens}
                  showTotal={(total) => `Total de ${total} itens`}
                  defaultPageSize={5}
                  defaultCurrent={1}
                  onChange={onChange}
                  current={currentPosition}
                  locale={{ items_per_page: "/ Página"}}
                />
              )}
            </>
          )}
        </VStack>
      </VStack>
      </Drawer>
    </>
  );
  return {
    DrawerModal,
    showDrawerCriarModal,
    contextHolderDrawerCreateVersion: contextHolder,
  };
};
