import {
  FormControl,
  FormLabel,
  VStack
} from "@chakra-ui/react";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Drawer,
  Form,
  Input,
  Select,
  Select as SelectAnt,
  Space,
  Typography,
} from "antd";
import ptBR from "antd/es/locale/pt_BR";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  codigoVersaoErrosGeraisAtom,
  contratoErrosGeraisAtom,
  dataExecDataFinalErrosGerais,
  dataExecDataInicialErrosGerais,
  descricaoErrosGeraisAtom,
  ocorrenciaDataFinalErrosGerais,
  ocorrenciaDataInicialErrosGerais,
  pracaErrosGeraisAtom,
  programaErrosGeraisAtom
} from "../../atom/formFiltroErrosLogs";
import {
  atualizacaoDataInicial,
  dataAgendamento,
  dataAtualizacao,
  dataOcorrenciaEnd,
  dataOcorrenciaStart,
  erroLog,
  pendenciaConciliacao,
  rejeicaoFiscal,
  statusAtualizacaoAtom,
  statusExecucao,
  versaoEmUsoAtom
} from "../../atom/formFiltroPrincipal";
import { UseMessageApi } from "../../message/UseMessageApi";
import "../versoes/versoes.css";
const { Text: Text, Title, Link } = Typography;
const { Option } = SelectAnt;
const { RangePicker } = DatePicker;
export const useDrawerModalFiltroErrosAll = (
  setFetch: React.Dispatch<React.SetStateAction<boolean>>,
  fetch: boolean,
  openDrawer: boolean,
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [form] = Form.useForm();

  //
  const { contextHolder, error, success, warning } = UseMessageApi();

  const [textContrato, setTextContrato] = useRecoilState(
    contratoErrosGeraisAtom
  );
  const [textPraca, setTextPraca] = useRecoilState(pracaErrosGeraisAtom);

  const [textOcorrenciaDataFinal, setTextOcorrenciaDataFinal] = useRecoilState(
    ocorrenciaDataFinalErrosGerais
  );
  const [textOcorrenciaDataInicial, setTextOcorrenciaDataInicial] = useRecoilState(
    ocorrenciaDataInicialErrosGerais
  );
  const [textAtualizacaoDataInicial, setTextAtualizacaoDataInicial] =
    useRecoilState(atualizacaoDataInicial);

  const [textDataExecDataInicial, setTextDataExecDataInicial] =
    useRecoilState(dataExecDataInicialErrosGerais);
  const [textDataExecDataFinal, setTextDataExecDataFinal] = useRecoilState(
    dataExecDataFinalErrosGerais
  );
  
  //################AQUI
  //verificar função handleFIlter();

  const [textCodigoVersao, setTextCodigoVersao] = useRecoilState(
    codigoVersaoErrosGeraisAtom
  );
  const [textDescricao, setTextDescricao] = useRecoilState(
    descricaoErrosGeraisAtom
  );
  const [programa, setPrograma] = useRecoilState(
    programaErrosGeraisAtom
  );


  const [loading, setLoading] = useState(false);


  const showDrawerCriarModal = () => {
    setOpenDrawer(true);
  };
  const onCloseDrawerModal = () => {
    setOpenDrawer(false);
  };
  useEffect(() => {}, []);


  const handleRangeChangePeriodoDataExecutavel = (dates: any) => {
    //getErrosAll();
    if (dates) {
      const startDate = dates[0];
      const endDate = dates[1];
      const formattedStartDate = startDate
        ? startDate.format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
      //setTextDataAgendamento(formattedRange);
      setTextDataExecDataInicial(formattedStartDate);
      setTextDataExecDataFinal(formattedEndDate);
    } else {
      setTextDataExecDataInicial("");
      setTextDataExecDataFinal("");
    }
  };
  const handleRangeChangePeriodoDataAtualizacao = (dates: any) => {
    //getErrosAll();
    if (dates) {
      const startDate = dates[0];
      const endDate = dates[1];
      const formattedStartDate = startDate
        ? startDate.format("YYYY-MM-DD")
        : "";

      const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
      //setTextDataAgendamento(formattedRange);
      setTextOcorrenciaDataInicial(formattedStartDate);
      setTextOcorrenciaDataFinal(formattedEndDate);
    } else {
      setTextOcorrenciaDataInicial("");
      setTextOcorrenciaDataFinal("");
    }
  };

  console.log(textContrato)

  const DrawerModal = (
    <>
      {contextHolder}
      <Drawer
        title="Filtros"
        width={"320px"}
        onClose={onCloseDrawerModal}
        open={openDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        // extra={
        //   <Space>
        //     <Button loading={loading} type="primary" onClick={()=>{onCloseDrawerModal()}}>
        //       Buscar
        //     </Button>
        //   </Space>
        // }
      >
        <VStack w={"full"} spacing={5}>
          <VStack w="100%" spacing={5}>
            <FormControl>
              <FormLabel>Contrato </FormLabel>
              <Input
                value={textContrato}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTextContrato(e.target.value);
                }}
                placeholder={"Ex: 0000"}
              />
              <VStack w={50}></VStack>
            </FormControl>
            <FormControl>
              <FormLabel>Data de Ocorrência </FormLabel>
              <VStack w={"full"}>
                <ConfigProvider locale={ptBR}>
                  <RangePicker
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY"
                    placeholder={["Inicial", "Final"]}
                    //value={[currentDayjs, currentDayjs]}
                    onChange={handleRangeChangePeriodoDataAtualizacao}
                  />
                </ConfigProvider>
              </VStack>
            </FormControl>
            <FormControl>
              <FormLabel>Data do Executável </FormLabel>
              <VStack w={"full"}>
                <ConfigProvider locale={ptBR}>
                  <RangePicker
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY"
                    placeholder={["Inicial", "Final"]}
                    //value={[currentDayjs, currentDayjs]}
                    onChange={handleRangeChangePeriodoDataExecutavel}
                  />
                </ConfigProvider>
              </VStack>
            </FormControl>
            <FormControl>
              <FormLabel>Código da Versão </FormLabel>
              <Input
                type={"text"}
                value={textCodigoVersao}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTextCodigoVersao(e.target.value);
                  //handleFIlter();
                }}
                placeholder={"Ex: X8"}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Descrição </FormLabel>
              <Input
                type={"text"}
                value={textDescricao}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTextDescricao(e.target.value);
                  //handleFIlter();
                }}
                placeholder={"Descrição simplificada"}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Programa </FormLabel>
              <Input
                type={"text"}
                value={programa}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPrograma(e.target.value);
                  //handleFIlter();
                }}
                placeholder={"Ex: PDV"}
              />
            </FormControl>
            <VStack alignItems={"start"} w={"full"}>
            <FormLabel>Praça</FormLabel>
            <Select
              style={{ width: "100%" }}
              value={textPraca}
              onChange={(e) => {
                setTextPraca(e);
              }}
              placeholder="Por favor insira a praça desejada"
            >
              <Option value="-1">Selecione</Option>
              <Option value="MA000001">PARACATU</Option>
              <Option value="MA000003">UNAI</Option>
              <Option value="MA000004">BRASILIA</Option>
              <Option value="MA000005">BRASILANDIA DE MINAS</Option>
              <Option value="MA000006">JOÃO PINHEIRO</Option>
              <Option value="MA000008">ARAXA</Option>
              <Option value="MA000009">VAZANTE</Option>
              <Option value="MA000013">CRISTALINA</Option>
              <Option value="MA000028">TRÊS MARIAS</Option>
              <Option value="MA000031">MATRIZ</Option>
            </Select>
          </VStack>
          </VStack>
        </VStack>
      </Drawer>
    </>
  );
  return {
    DrawerModal,
    showDrawerCriarModal,
    contextHolderDrawerCreateVersion: contextHolder,
    textContrato,
  };
};
