import { Button, Center, Tag } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { nomeUsuario } from "../atom/usuario";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import CardsDataProvider from "./principal_page/context/DadosCardContext";
import { User } from "./User";

export const UserPage = () => {
  return (
    <>
      <CardsDataProvider>
        <User/>
      </CardsDataProvider>
    </>
  );
};
