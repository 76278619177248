import { atom } from "recoil";

export const enviarErroLogConfig = atom({
    key: 'enviarErroLogConfig',
    default: 0,
  });
  export const enviarConciliacaoConfig = atom({
    key: 'enviarConciliacaoConfig',
    default: 0,
  });
  export const enviarRejeicaoFiscalConfig = atom({
    key: 'enviarRejeicaoFiscalConfig',
    default: 0,
  });
  export const receberMensagemConfig = atom({
    key: 'receberMensagemConfig',
    default: 0,
  });

  export const enviarBoletosConfig = atom({
    key: 'enviarBoletosConfig',
    default: 0,
  });

  export const cobrancasConfig = atom({
    key: 'cobrancasCOnfig',
    default: 0,
  });
  export const razaoSocialConfig = atom({
    key: 'razaoSocialConfig',
    default: '',
  });
  export const dadosCarregadosConfig = atom({
    key: 'dadosCarregadosConfig',
    default: false,
  });

