import { contratoAtom, dataAgendamento, dataAtualizacao } from './../atom/formFiltroPrincipal';
import axios, { AxiosError, AxiosResponse, HttpStatusCode, all } from 'axios'
import { Versao, VersaoUpdate } from '../components/versoes/type'
import { UsuarioData } from '../types/UsuarioData'
import { ConfiguracaoParametroTDO, EventoPayloadTDO } from './EventoPayloadTDO'
import { CardsData, ControleProcessosBody, ControleProcessosTDO, HomeObjet } from '../types/types'
import { Moment } from 'moment';

//export const apiEndPoint = 'http://localhost:3001'

//export const apiEndPoint = 'https://success.vps-kinghost.net'
//export const apiEndPoint = 'http://localhost:3060'
export const apiEndPoint = 'https://apiatualizacaoautomaticaproducao.successsistemas.com'
// export const apiEndPoint = 'http://localhost:3051';
//export const apiEndPoint = 'http://localhost:3050';
//lembrar de alterar a porta na api

export const api = axios.create({
  baseURL: apiEndPoint,
})

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('@App:token')

    if (token) {
      config.headers!['Authorization'] = 'Bearer ' + token
    }

    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  (error: AxiosError) => {
    Promise.reject(error)
  }
)

export const apiGetUser = async () => {
  try {
    const res = await api.get<UsuarioData>('user')
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}

export const apiGetEventos = async () => {
  try {
    const res = await api.get<any>('eventos')
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const apiGetEventoByHash = async (hash: string) => {
  try {
    const res = await api.get<any>('eventos/md5/' + hash)
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const apiGetAllEventos = async (text: string) => {
  if (text === 'all') {
    try {
      const res = await api.get<any>('eventos/all')
      return res
    } catch (err) {
      if ((err as any).response) return { data: null, error: err }
      throw err
    }
  } else {
    try {
      const res = await api.get<any>('eventos/getEventByTitulo/' + text)
      return res
    } catch (err) {
      if ((err as any).response) return { data: null, error: err }
      throw err
    }
  }
}

export const apiAddEvento = async (payload: EventoPayloadTDO) => {
  try {
    const res = await api.post('eventos', payload)
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}

export const configurarParametro = async (
  payload: ConfiguracaoParametroTDO
) => {
  try {
    const res = await api.post('config-parametros', payload)
    return res
  } catch (error) {
    console.error('Erro:', error)
    throw error
  }
}
export const getUsuario = async (
) => {
  try {
    const res = await api.get('user')
    return res
  } catch (error) {
    console.error('Erro:', error)
    throw error
  }
}
export const getConfiguracaoParametro = async (
  payload: ConfiguracaoParametroTDO
) => {
  try {
    const res = await api.get('config-parametros')
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}

export const getUsuarios = async (page: number, limit: number) => {
  try {
    const res = await api.get(`logs/pagination?page=${page}&limit=${limit}`)
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}

export const getErros = async (
  page: number,
  limit: number,
  start: Moment | null,
  end: Moment | null,
  contrato: string,
  startDataExecutavel: Moment | null,
  endDataExecutavel: Moment | null,
  codigoVersao: string,
  descricao: string,
  programa: string
) => {
  try {
    //09-02-2022 09-02-2023
    //const res = await api.get(
    // `logs/pagination?page=${page}&limit=${limit}&end=${start}&start=${end}`
    // )
    console.clear();
    console.log("hello")
    console.log(startDataExecutavel?.toString(), endDataExecutavel?.toString())

    const param = {
      limit: 10,
      page: page,
      ...((start?.toString() !== null && start?.toString() !== undefined) && { start: start?.toString() }),
      ...((end?.toString() !== null && end?.toString() !== undefined) && { end: end?.toString() }),
      ...((startDataExecutavel?.toString() !== null && startDataExecutavel?.toString() !== undefined) && { data_executavel_inicio: startDataExecutavel?.toString() }),
      ...((endDataExecutavel?.toString() !== null && endDataExecutavel?.toString() !== undefined) && { data_executavel_fim: endDataExecutavel?.toString() }),
      ...((codigoVersao?.toString() !== null && codigoVersao?.toString() !== undefined) && { codigo_versao: codigoVersao?.toString() }),
      ...((descricao?.toString() !== null && descricao?.toString() !== undefined) && { descricao: descricao?.toString() }),
      ...((programa?.toString() !== null && programa?.toString() !== undefined) && { programa: programa?.toString() }),
      contrato: contrato
    };

    const queryParams = new URLSearchParams({
      ...param,
      limit: String(param.limit),
      page: String(param.page)
    });

    const res = await api.get(
      `logs/pagination`,
      {
        params: queryParams
      }
    )
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const getPraca = async () => {
  try {
    const res = await api.get(
      `controle-versao/verificar-usuario`,
    )
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const getErrosAll = async (
  page: number,
  limit: number,
  start: string | null,
  end: string | null,
  startDataExecutavel: string | null,
  endDataExecutavel: string | null,
  codigoVersao: string,
  descricao: string,
  programa: string,
  contrato: string,
  praca: string
) => {
  try {
    //09-02-2022 09-02-2023
    //const res = await api.get(
    // `logs/pagination?page=${page}&limit=${limit}&end=${start}&start=${end}`
    // )
    const param = {
      limit: all,
      page: page,
      ...((start?.toString() !== null && start?.toString() !== undefined) && { start: end?.toString() }),
      ...((end?.toString() !== null && end?.toString() !== undefined) && { end: start?.toString() }),
      ...((startDataExecutavel?.toString() !== null && startDataExecutavel?.toString() !== undefined) && { data_executavel_inicio: startDataExecutavel?.toString() }),
      ...((endDataExecutavel?.toString() !== null && endDataExecutavel?.toString() !== undefined) && { data_executavel_fim: endDataExecutavel?.toString() }),
      ...((codigoVersao?.toString() !== null && codigoVersao?.toString() !== undefined) && { codigo_versao: codigoVersao?.toString() }),
      ...((descricao?.toString() !== null && descricao?.toString() !== undefined) && { descricao: descricao?.toString() }),
      ...((programa?.toString() !== null && programa?.toString() !== undefined) && { programa: programa?.toString() }),
      ...((contrato?.toString() !== null && contrato?.toString() !== undefined) && { contrato: contrato?.toString() }),
      ...((praca?.toString() !== null && praca?.toString() !== undefined) && { praca: praca?.toString() }),
    };

    const queryParams = new URLSearchParams({
      ...param,
      limit: String(param.limit),
      page: String(param.page)
    });

    const res = await api.get(
      `logs/pagination/all`,
      {
        params: queryParams
      }
    )
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const getRejeicoesFiscais = async (
  page: number,
  limit: number,
  start: string,
  end: string,
  contrato: string
) => {
  try {
    //09-02-2022 09-02-2023
    //const res = await api.get(
    // `logs/pagination?page=${page}&limit=${limit}&end=${start}&start=${end}`
    // )

    const param = {
      limit: 10,
      page: 1,
      ...(start.length > 0 ? { start: start } : {}),
      ...(end.length > 0 ? { end: end } : {}),
      contrato: contrato
    };

    const queryParams = new URLSearchParams({
      ...param,
      limit: String(param.limit),
      page: String(param.page)
    });

    const res = await api.get(
      `rejeicoes-fiscais/pagination`,
      {
        params: queryParams
      }
    )
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const getPendenciasConciliacao = async (
  page: number,
  limit: number,
  start: string,
  end: string,
  contrato: string
) => {
  try {
    //09-02-2022 09-02-2023
    //const res = await api.get(
    // `logs/pagination?page=${page}&limit=${limit}&end=${start}&start=${end}`
    // )

    const param = {
      limit: 10,
      page: 1,
      ...(start.length > 0 ? { start: start } : {}),
      ...(end.length > 0 ? { end: end } : {}),
      contrato: contrato
    };

    const queryParams = new URLSearchParams({
      ...param,
      limit: String(param.limit),
      page: String(param.page)
    });

    const res = await api.get(
      `pendencias-conciliacao/pagination`,
      {
        params: queryParams
      }
    )
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const getDetails = () => {
  return 3 / 0.5
}

export const getDias = async (start: Moment | null, end: Moment | null) => {
  try {
    const res = await api.get(`logs/datas?start=${end}&end=${start}`)
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}

export const getErroDetail = async (id: number) => {
  try {
    const res = await api.get(`logs/orderBy?id=${id}`)
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const getFilesName = async () => {
  try {
    const res = await api.get(`controle-versao/versionnames`)
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}

export const criarVersao = async (versao: Versao) => {
  try {
    const res = await api.post(`controle-versao`, versao)
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}

export const getVersoes = async (page: number, limit: number, textNomeVersaoQuery: string): Promise<any> => {

  const query = {
    page,
    limit,
    ...(textNomeVersaoQuery.length > 0 && { textNomeVersaoQuery: textNomeVersaoQuery }),
  }
  try {
    const res = await api.get(`controle-versao`, {
      params: query
    })
    console.log('Status Code:', res.status) // Exibe o statusCode no console
    return res
  } catch (error) {
    console.error('Erro:', error)
    throw error
  }
}

export const getSingleVersion = async (id: number) => {
  try {
    const res = await api.get(`controle-versao/sigle-version?id=${id}`)
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}


export const updateSingleVersion = async (id: number, versao: VersaoUpdate) => {
  try {
    const res = await api.put<AxiosResponse>(`controle-versao/sigle-version?id=${id}`, versao)
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}

export const getConfiguracaoBancoDados = async () => {
  try {
    const res = await api.get(`configuracao-db`)
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}

export const getGerenciamentoAtualizacoesClientesDados = async (
  page: number,
  limit: number,
  contrato: string,
  codigoVersao: string,
  statusExecucao: number,
  dataAgendadaInicial: string,
  dataAgendadaFinal: string,
  dataAtualizacaoInicial: string,
  dataAtualizacaoFinal: string,
  statusAtualizacao: number,
  especie: number,
  situacaoModulo: number,
  textPraca: string
) => {
  try {
    const params = {
      limit: all,
      ...(page > 0 ? { page } : {}),
      ...(contrato.length > 0 ? { contrato } : {}),
      ...(codigoVersao.length > 0 ? { codigo_versao: codigoVersao } : {}),
      ...(dataAgendadaInicial.length > 0 ? { dataAgendamentoInicial: dataAgendadaInicial } : {}),
      ...(dataAgendadaFinal.length > 0 ? { dataAgendamentoFinal: dataAgendadaFinal } : {}),
      ...(dataAtualizacaoInicial.length > 0 ? { dataAtualizacaoInicial: dataAtualizacaoInicial } : {}),
      ...(dataAtualizacaoFinal.length > 0 ? { dataAtualizacaoFinal: dataAtualizacaoFinal } : {}),
      ...({ status_execucao: statusExecucao }),
      ...({ status_atualizacao: statusAtualizacao }),
      ...({ situacaoModulo: situacaoModulo }),
      ...({ especie: especie }),
      ...({ textPraca: textPraca }),
    }
    const res = await api.get(
      `gerenciamento-atualizacao-clientes/pagination`,
      {
        params: params
      }
    )
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const getControleProcessoContrato = async (
  page: number,
  limit: number,
  contrato: string,
) => {
  try {
    const params = {
      ...(page > 0 ? { page } : {}),
      ...(limit > 0 ? { limit } : {}),
      ...(contrato.length > 0 ? { contrato } : {}),
    }
    const res = await api.get(
      `controle-processoscontrato/pagination`,
      {
        params: params
      }
    )
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const getDataVersionFile = async (
  page: number,
  limit: number,
) => {
  try {
    const params = {
      ...(page > 0 ? { page } : {}),
      ...(limit > 0 ? { limit } : {}),
    }
    const res = await api.get(
      `controle-versao/datafilenames`,
      {
        params: params
      }
    )
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const getRascunho = async (
) => {
  try {
    const res = await api.get(
      `controle-versao/verificar-rascunho`
    )
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const getCardDatas = async (): Promise<CardsData> => {
  try {
    const response = await api.get('main/cards-data');
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(`Failed to get card data: ${error.message}`);
    }
    throw error;
  }
};
export const getConfiguracaoPorContrato = async (contrato: string): Promise<ControleProcessosTDO> => {

  const query = {
    contrato
  }
  try {
    const response = await api.get('controle-processoscontrato/configuracao-contrato', {
      params: query
    });
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(`Failed to get card data: ${error.message}`);
    }
    throw error;
  }
};
export const createConfiguracaoPorContrato = async (body: ControleProcessosBody) => {

  try {
    const response = await api.post('controle-processoscontrato/configuracao-contrato', body);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(`Failed to get card data: ${error.message}`);
    }
    throw error;
  }
};
export const getListMain = async (
  page: number,
  limit: number | string,
  contrato: string,
  versaoEmUso: string,
  statusExecucao: string,
  statusAtualizacao: string,
  dataAgendamentoInicial: string,
  dataAgendamentoFinal: string,
  dataAtualizacaoInicial: string,
  dataAtualizacaoFinal: string,
  textDataOcorrenciaStart: string,
  textDataOcorrenciaFinal: string,
  textCodigoRejeicao: string,
  textModeloDocumento: string,
  textRejeicoesFiscais: string,
  textPendenciaConciliacao: string,
  textErroLog: string,
  pracaFiltro: string,
  textEspecie: string,
  textSituacaoModulo: string
) => {
  // console.log(dataAgendamento, dataAtualizacao)
  const query = {
    pagina: page,
    limite: limit,
    ...(contrato !== '' && { contratoAtom: contrato }),
    ...(versaoEmUso !== '' && { versaoEmUsoAtom: versaoEmUso }),
    ...(statusExecucao !== '-1' && { statusExecucao: statusExecucao }),
    ...(statusAtualizacao !== '-1' && { statusAtualizacaoAtom: statusAtualizacao }),
    ...(dataAgendamentoInicial !== '' && { dataAgendamentoInicial: dataAgendamentoInicial }),
    ...(dataAgendamentoFinal !== '' && { dataAgendamentoFinal: dataAgendamentoFinal }),
    ...(dataAtualizacaoInicial !== '' && { dataAtualizacaoInicial: dataAtualizacaoInicial }),
    ...(dataAtualizacaoFinal !== '' && { dataAtualizacaoFinal: dataAtualizacaoFinal }),
    ...(textCodigoRejeicao !== '' && { codigoRejeicao: textCodigoRejeicao }),
    ...(textDataOcorrenciaStart !== '' && { dataOcorrenciaStart: textDataOcorrenciaStart }),
    ...(textDataOcorrenciaFinal !== '' && { dataOcorrenciaEnd: textDataOcorrenciaFinal }),
    ...((textModeloDocumento !== '' && textModeloDocumento !== 'Todos') && { modeloDocumento: textModeloDocumento }),
    ...(textRejeicoesFiscais !== '' && { rejeicaoFiscal: textRejeicoesFiscais }),
    ...(textRejeicoesFiscais !== '' && { rejeicaoFiscal: textRejeicoesFiscais }),
    ...(textPendenciaConciliacao !== '' && { pendenciaConciliacao: textPendenciaConciliacao }),
    ...(textErroLog !== '' && { erroLog: textErroLog }),
    ...(pracaFiltro !== '' && { pracaFiltro: pracaFiltro }),
    ...(textEspecie !== '' && { especie: textEspecie }),
    ...(textSituacaoModulo !== '' && { situacaoModulo: textSituacaoModulo }),

  }
  try {
    const response = await api.get('main', {
      params: query
    });
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(`Failed to get main List ${error.message}`);
    }
    throw error;
  }
};
export const getListHistorico  = async (
  page: number,
  limit: number,
  contrato: string,
  versaoEmUso: string,
  statusExecucao: string,
  statusAtualizacao: string,
  dataAgendamentoInicial: string,
  dataAgendamentoFinal: string,
  dataAtualizacaoInicial: string,
  dataAtualizacaoFinal: string,
  textDataOcorrenciaStart: string,
  textDataOcorrenciaFinal: string,
  textCodigoRejeicao: string,
  textModeloDocumento: string,
  textRejeicoesFiscais: string,
  textPendenciaConciliacao: string,
  textErroLog: string,
  pracaFiltro: string,
  textEspecie: string,
  textSituacaoModulo: string
) => {
  // console.log(dataAgendamento, dataAtualizacao)
 
  const query = {
    pagina: page,
    limite: limit,
    ...(contrato !== '' && { contratoAtom: contrato }),
    ...(versaoEmUso !== '' && { versaoEmUsoAtom: versaoEmUso }),
    ...(statusExecucao !== '-1' && { statusExecucao: statusExecucao }),
    ...(statusAtualizacao !== '-1' && { statusAtualizacaoAtom: statusAtualizacao }),
    ...(dataAgendamentoInicial !== '' && { dataAgendamentoInicial: dataAgendamentoInicial }),
    ...(dataAgendamentoFinal !== '' && { dataAgendamentoFinal: dataAgendamentoFinal }),
    ...(dataAtualizacaoInicial !== '' && { dataAtualizacaoInicial: dataAtualizacaoInicial }),
    ...(dataAtualizacaoFinal !== '' && { dataAtualizacaoFinal: dataAtualizacaoFinal }),
    ...(textCodigoRejeicao !== '' && { codigoRejeicao: textCodigoRejeicao }),
    ...(textDataOcorrenciaStart !== '' && { dataOcorrenciaStart: textDataOcorrenciaStart }),
    ...(textDataOcorrenciaFinal !== '' && { dataOcorrenciaEnd: textDataOcorrenciaFinal }),
    ...((textModeloDocumento !== '' && textModeloDocumento !== 'Todos') && { modeloDocumento: textModeloDocumento }),
    ...(textRejeicoesFiscais !== '' && { rejeicaoFiscal: textRejeicoesFiscais }),
    ...(textRejeicoesFiscais !== '' && { rejeicaoFiscal: textRejeicoesFiscais }),
    ...(textPendenciaConciliacao !== '' && { pendenciaConciliacao: textPendenciaConciliacao }),
    ...(textErroLog !== '' && { erroLog: textErroLog }),
    ...(pracaFiltro !== '' && { pracaFiltro: pracaFiltro }),
    ...(textEspecie !== '' && { textEspecie: textEspecie }),
    ...(textSituacaoModulo !== '' && { textSituacaoModulo: textSituacaoModulo }),

  }
  try {
    const response = await api.get('main/historico', {
      params: query
    });
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(`Failed to get main List ${error.message}`);
    }
    throw error;
  }
};
export const deleteFileVersion = async (
  filename: string,
) => {
  try {
    const params = {
      filename
    }
    const res = await api.delete(
      `controle-versao/datafilenames`,
      {
        params: params
      }
    )
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const deleteSingleVersion = async (id: number) => {
  try {
    const res = await api.delete(`controle-versao/sigle-version?id=${id}`)
    return res
  } catch (err) {
    if ((err as any).response) return { data: null, error: err }
    throw err
  }
}
export const deleteSingleV = async (id: string, callback: (loading: boolean, completed: boolean) => void) => {
  try {
    callback(true, false);
    const response = await api.delete(`controle-versao/sigle-version?id=${id}`);
    if (response?.status === HttpStatusCode.NoContent) {
      callback(false, true);
    } else {
      callback(false, false)
    }
  } catch (error) {
    callback(false, false)
  }
}