import { VStack } from "@chakra-ui/react";
import { Descriptions, Input, InputNumber, Typography } from "antd";
import { useRecoilState } from "recoil";
import { delecaoErrosLog, delecaoPendenciaConciliacao, delecaoRejeicaoFiscais } from "../../atom/formConfiguracaoParameteosAtom";
const { Text } = Typography;

export const InputsParametro = () => {

  const [delecaoTime, setTime ] = useRecoilState(delecaoErrosLog);
  const [delecaoPendenciaTime, setDelecaoPendenciaConciliacao] = useRecoilState(
    delecaoPendenciaConciliacao
  );
  const [delecaoRejeicoesTime, setRejeicoesFiscais] = useRecoilState(delecaoRejeicaoFiscais);

  return (
    <VStack w={"full"} px={5} py={4}>
      <Descriptions>
        <Descriptions.Item>
          <VStack>
            <Text type="secondary">Deleção Erros de Log</Text>
            <InputNumber style={{width: '50%'}} type="number" value={delecaoTime} onChange={(e) => {setTime(e ?? 0)}} placeholder="Tempo em Dias" />
            <Text type="secondary">Dias</Text>
          </VStack>
        </Descriptions.Item>
        <Descriptions.Item>
          <VStack>
            <Text type="secondary">Deleção Pendência de Conciliação</Text>
            <InputNumber style={{width: '50%'}} type="number" value={delecaoPendenciaTime} onChange={(e) => {setDelecaoPendenciaConciliacao(e ?? 0)}} placeholder="Tempo em Dias" />
            <Text type="secondary">Dias</Text>          
          </VStack>
        </Descriptions.Item>
        <Descriptions.Item>
       <VStack>
       <Text type="secondary">Deleção Rejeições Fiscais</Text>
       <InputNumber style={{width: '50%'}} type="number" value={delecaoRejeicoesTime} onChange={(e) => {setRejeicoesFiscais(e ?? 0)}} placeholder="Tempo em Dias" />
       <Text type="secondary">Dias</Text>
       </VStack>
        </Descriptions.Item>
      </Descriptions>
    </VStack>
  );
};
