import { VStack } from "@chakra-ui/react";
import { Checkbox, Descriptions } from "antd";
import { erroLog, pendenciaConciliacao, rejeicaoFiscal } from "../../atom/formFiltroPrincipal";
import { useRecoilState } from "recoil";

export const FiltroCheckers = () => {
  const [textErroLog, setTextErroLog] =
  useRecoilState(erroLog);
  const [textPendencia, setTextPendencia] =
  useRecoilState(pendenciaConciliacao);
  const [textRejeicoes, setTextRejeicoes] =
  useRecoilState(rejeicaoFiscal);

  const handleCheckboxChange = (state:any, setState:any) => {
    setState(state ? 1 : 0);
  };

  return (
    <VStack w="full" alignItems={"start"}>
      <Checkbox style={{marginLeft: 8}}  defaultChecked={false} onChange={(e) => handleCheckboxChange(e.target.checked, setTextErroLog)}>Erro de Log</Checkbox>
      <Checkbox defaultChecked={false} onChange={(e) => handleCheckboxChange(e.target.checked, setTextPendencia)}>Pendência de Conciliação</Checkbox>
      <Checkbox  defaultChecked={false} onChange={(e) => handleCheckboxChange(e.target.checked, setTextRejeicoes)}>Rejeições Fiscais</Checkbox>
    </VStack>
  );
};
