import { Button, Popconfirm, Space, Tag, Tooltip, Typography } from "antd";
import { Button as ButtonChakara } from "@chakra-ui/react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import type { ColumnsType } from "antd/es/table";
import { DataType } from "../../types/types";
import { reduceText, stringToDateWithHour } from "../../util/util";
import { Versao } from "./type";
import { deleteSingleV } from "../../api/api";
import { UseMessageApi } from "../../message/UseMessageApi";
import { useContext, useEffect, useState } from "react";
import { VersaoContext } from "./context/VersaoContext";
import { DeleteVersaoContext } from "./context/DeleteVersaoContext";
const { Link } = Typography;

export const UseColumnsVersionApi = (
  getVersoesBanco: () => void,
  showUpdateModal: () => void
) => {
  const { success, error, contextHolder } = UseMessageApi();
  const { versao, setVersao } = useContext(VersaoContext);
  const {} = useContext(DeleteVersaoContext);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [versaoDelete, setVersaoDelete] = useState<Versao>();
  const showPopconfirm = (versao: Versao) => {
    setVersaoDelete(versao);
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await deleteFileVersion(versaoDelete?.id ?? "");
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  async function deleteFileVersion(id: string) {
    try {
      await deleteSingleV(id, (loading, completed) => {
        if (completed) {
          success(`A versão com ${id} foi excluídos`);
          getVersoesBanco();
        } else {
          if (!loading)
            error(`Ocorreu um erro ao excluir o a versão com id ${id}`);
        }
      });
    } catch (error) {
      console.error(`Erro ao excluir o item ${id}`);
    }
  }

  const columns: ColumnsType<Versao> = [

    {
      title: "Código",
      dataIndex: "codigo",
      key: "codigo",
      render: (text: string) => {
        return (
          <>
            <Tooltip title={text}>
              <a>{reduceText(text, 30)}</a>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Lançamento",
      dataIndex: "data_lancamento",
      key: "data_lancamento",
      render: (data: string) => {
        return stringToDateWithHour(data);
      },
    },
    {
      title: "Atualização",
      dataIndex: "atualizado_em",
      key: "atualizado_em",
      render: (data: string) => {
        return stringToDateWithHour(data);
      },
    },
    {
      title: "Arquivo",
      dataIndex: "nome_arquivo",
      key: "nome_arquivo",
    },
    {
      title: "Tempo De Atualização",
      dataIndex: "tempo_medio_atualizacao",
      key: "tempo_medio_atualizacao",
      render: (value: string, record: Versao) => {
        return <>{value} Minutos</>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: number) => {
        const online = status === 1 ? true : false;
        return (
          <Tag
            style={{ width: "70px", textAlign: "center" }}
            color={online ? "#87d068" : "#d07268"}
          >
            {online ? "Liberado" : "Bloqueado"}
          </Tag>
        );
      },
    },
    {
      title: "Ação",
      dataIndex: "acao",
      key: "acao",
      render: (_, record: any) => {
        const dataType: Versao = record;
        return (
          <>
            <Space size={"middle"}>
              <ButtonChakara
                size={"xs"}
                colorScheme="blue"
                variant={"ghost"}
                onClick={() => {
                  showUpdateModal();
                  setVersao(dataType);
                }}
              >
                <AiFillEdit />
              </ButtonChakara>
  
              <Popconfirm
                placement="top"
                title={"Confirmaçao de deleção"}
                description={"Deseja realmente apagar essa versão?"}
                onConfirm={() => {
                  deleteFileVersion(dataType?.filename_upload);
                }}
                okText="Sim"
                cancelText="Não"
              >
                <ButtonChakara
                  variant={"ghost"}
                  colorScheme="red"
                  size={"xs"}
                  onClick={() => {
                    //aqui também
                    showPopconfirm(dataType);
                  }}
                >
                  <AiFillDelete />
                </ButtonChakara>
              </Popconfirm>
            </Space>
          </>
        );
      },
    },
  ];

  return {
    columns,
    contextHolderColumnVersion: contextHolder,
    showUpdateModal,
    setVersao,
  };
};
