import { SaveOutlined } from "@ant-design/icons";
import { Button, HStack, Spacer, VStack, useToast } from "@chakra-ui/react";
import { Typography } from "antd";
import { ConfiguracaoParametroTDO } from "../../api/EventoPayloadTDO";
import { useState } from "react";
import { configurarParametro } from "../../api/api";
import { useRecoilState } from "recoil";
import { delecaoErrosLog, delecaoPendenciaConciliacao, delecaoRejeicaoFiscais } from "../../atom/formConfiguracaoParameteosAtom";
const { Text, Title, Link } = Typography;

export const TitleParametro = () => {

  const toast = useToast();
  const [loading, setLoading] = useState(false)
  const [delecaoTime,  ] = useRecoilState(delecaoErrosLog);
  const [delecaoPendenciaTime, ] = useRecoilState(delecaoPendenciaConciliacao);
  const [delecaoRejeicoesTime,] = useRecoilState(delecaoRejeicaoFiscais);


  const salvar = () => {
    const payload: ConfiguracaoParametroTDO = {
      tempo_eliminacao_log: delecaoTime,
      tempo_eliminacao_pendencia_concliliacao: delecaoPendenciaTime,
      tempo_eliminacao_rejeicoes: delecaoRejeicoesTime
    };
    setLoading(true);
    configurarParametro(payload)
      .then((result) => {
        console.log("ok it works");
        setLoading(false);
        toast({
          title: "Configuração salva com sucesso",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.log("not of this is working here, because of none");
        toast({
          title: "Erro ao salvar configuração",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  return (
    <VStack px={5} py={4} alignItems={"start"} w="full">
      <Title level={3}>Configurações de Parâmetros</Title>
      <HStack w={"full"}>
        <Text type="secondary">
          Gerenciamento de Parâmetros para deleção de logs.
        </Text>

        <Spacer />
        <Button
        isLoading={loading}
          variant={"solid"}
          leftIcon={<SaveOutlined />}
          colorScheme="blue"
          onClick={salvar}
        >
          Salvar
        </Button>
      </HStack>
    </VStack>
  );
};
