import { Checkbox, Descriptions } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  cobrancasConfig,
  dadosCarregadosConfig,
  enviarBoletosConfig,
  enviarConciliacaoConfig,
  enviarErroLogConfig,
  enviarRejeicaoFiscalConfig,
  razaoSocialConfig,
  receberMensagemConfig,
} from "../../atom/formConfiguracaoPorContratoAtom";
import { intToBoolean } from "../../util/util";
import { useParams } from "react-router-dom";
import { VStack } from "@chakra-ui/react";

export const ContratoProcessoInfo = () => {
  const [enviarErroLog, setEnviarErroLog] = useRecoilState(enviarErroLogConfig);
  const [enviarRejeicao, setEnviarRejeicao] = useRecoilState(enviarConciliacaoConfig);
  const [enviarCOnciliacao, setEnviarCOnciliacao] = useRecoilState(enviarRejeicaoFiscalConfig);
  const [receberMensagem, setReceberMensagem] = useRecoilState(receberMensagemConfig);
  const [enviarBoletos, setEnviarBoletos] = useRecoilState(enviarBoletosConfig);
  const [enviarCobrancas, setEnviarCobrancas] = useRecoilState(cobrancasConfig);
  const [razaoSocial, ] = useRecoilState(razaoSocialConfig);
  const {id} = useParams();

  const dadosCarregados = useRecoilValue(dadosCarregadosConfig);

  const handleCheckboxChange = (state:any, setState:any) => {
    setState(state ? 1 : 0);
  };

  return (
    <Descriptions>

      <Descriptions.Item label="Enviar erro de log">
        <Checkbox checked={intToBoolean(enviarErroLog)} onChange={(e) => handleCheckboxChange(e.target.checked, setEnviarErroLog)}></Checkbox>
      </Descriptions.Item>
      <Descriptions.Item label="Enviar conciliação fiscal">
        <Checkbox checked={intToBoolean(enviarCOnciliacao)} onChange={(e) => handleCheckboxChange(e.target.checked, setEnviarCOnciliacao)}></Checkbox>
      </Descriptions.Item>
      <Descriptions.Item label="Enviar rejeição fiscal">
        <Checkbox checked={intToBoolean(enviarRejeicao)} onChange={(e) => handleCheckboxChange(e.target.checked, setEnviarRejeicao)}></Checkbox>
      </Descriptions.Item>
      <Descriptions.Item label="Receber mensagens">
        <Checkbox checked={intToBoolean(receberMensagem)} onChange={(e) => handleCheckboxChange(e.target.checked, setReceberMensagem)}></Checkbox>
      </Descriptions.Item>
      <Descriptions.Item label="Enviar boletos">
        <Checkbox checked={intToBoolean(enviarBoletos)} onChange={(e) => handleCheckboxChange(e.target.checked, setEnviarBoletos)}></Checkbox>
      </Descriptions.Item>
      <Descriptions.Item label="Cobranças">
        <Checkbox checked={intToBoolean(enviarCobrancas)} onChange={(e) => handleCheckboxChange(e.target.checked, setEnviarCobrancas)}></Checkbox>
      </Descriptions.Item>
    </Descriptions>
  );
};
