import { useDisclosure, useToast, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { configurarParametro } from "../../api/api";
import { ConfiguracaoParametroTDO } from "../../api/EventoPayloadTDO";
import {
  delecaoErrosLog,
  delecaoPendenciaConciliacao,
  delecaoRejeicaoFiscais,
} from "../../atom/formConfiguracaoParameteosAtom";
import { ParametroContext } from "../../context/ParametroContext";
import { ConfiguracaoParametro } from "../versoes/type";
import { InputsParametro } from "./InputsParametro";
import { TitleParametro } from "./TitleParametro";
import { Alert } from "antd";
import { Loading } from "../../loading/Loading";
import { UsuarioVisualizacaoContext } from "../../context/UsuariosVisualizacaoContext";

export const Parametros = () => {
  const toast = useToast();
  const { parametro, mutate } = useContext(ParametroContext);

 

  const [, setDelecaoErroLog] = useRecoilState(delecaoErrosLog);
  const [, setDelecaoPendenciaConciliacao] = useRecoilState(
    delecaoPendenciaConciliacao
  );
  const [, setRejeicoesFiscais] = useRecoilState(delecaoRejeicaoFiscais);


  const [statusCode, setStatusCode] = useState(0)

  useEffect(() => {
 if(!parametro?.error){
  const parametroDados: ConfiguracaoParametro = parametro?.data[0];
  setDelecaoErroLog(parametroDados?.tempo_delete_registros_log);
  setDelecaoPendenciaConciliacao(
    parametroDados?.tempo_delete_pendencia_conciliacao
  );
  setRejeicoesFiscais(parametroDados?.tempo_delete_rejeicoes_fiscais);
 }else{
  setStatusCode(parametro?.error?.response?.status);
 }
    
  }, [parametro?.data]);

  if (statusCode === 500) {
    return (
      <Alert
        style={{ width: "100%" }}
        message="Error"
        description={"Ocorreu um erro no servidor"}
        type="error"
        showIcon
      />
    );
  }

  if (statusCode === 401) {
    return (
      <Alert
        style={{ width: "100%" }}
        message="Mensagem informativa"
        description={"Essa área está dispoível apenas para usuários da praça Matriz."}
        type="info"
        showIcon
      />
    );
  }
  if(parametro?.data ){
    return (
      <VStack w={"full"}>
        <TitleParametro />
        <VStack alignItems={"start"} w={"full"}>
          <InputsParametro />
        </VStack>
      </VStack>
    );
   }else{
    return <Loading/>
   }


};
