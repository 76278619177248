import React, { createContext, useState } from 'react';
import { Versao } from '../type';
import { deleteSingleV } from '../../../api/api';

interface DeleteVersaoContextProps {
    versao: Versao,
    setVersao: React.Dispatch<React.SetStateAction<Versao>>,
    deleteVersion : (id: number) => Promise<void>,
    setDeteleteVersion: React.Dispatch<React.SetStateAction<(id: number) => Promise<void>>>
}
const defautVersao:Versao = {
  versao: '',
  descricao: '',
  nome_arquivo: '',
  codigo: '',
  id: '',
  data_lancamento: '',
  tempo_medio_atualizacao: 0,
  status_execucao: 0,
  status: 0,
  filename_upload: ''
}
export const DeleteVersaoContext = createContext<DeleteVersaoContextProps>({
    versao: defautVersao,
    setVersao: () => {},
    deleteVersion: async (id: number) => {},
    setDeteleteVersion: () => {}

}) 

const DeleteVersaoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [versao, setVersao] = useState<Versao>(defautVersao);
    const [deleteVersion, setDeleteVersion] = useState<(id: number) => Promise<void>>(() => async (id: number) => {
    });

    

    return (
      <DeleteVersaoContext.Provider value={{ versao, setVersao, deleteVersion, setDeteleteVersion: setDeleteVersion}}>
        {children}
      </DeleteVersaoContext.Provider>
    );
  };
  export default DeleteVersaoProvider;