import { BoxProps, Center } from '@chakra-ui/react'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
export const Loading = (props: BoxProps) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  return (
    <Center h='85vh' {...props}>
    <Spin indicator={antIcon} />
    </Center>
  )
}
