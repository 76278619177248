import {
  Button,
  Center,
  HStack,
  Image,
  Select,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import {
  ConfigProvider,
  DatePicker,
  Pagination,
  PaginationProps,
  Table,
  Typography,
} from "antd";
import ptBR from "antd/es/locale/pt_BR";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import "moment/locale/pt-br";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DoubleLeft from "../../images/double-left-arrow.png";
import DoubleRigth from "../../images/double-rigth-arrow.png";

import { NotFoundEstatistica } from "./NotFoundEstatistica";
import { DiaSemana, Empresa } from "./types";

import dayjs, { Dayjs } from "dayjs";
import { criarPeriodo } from "./util";
import { PendenciaConciliacaoTDO } from "../../types/types";
import { getPendenciasConciliacao } from "../../api/api";
import { rowClassName, stringToDateWithHour } from "../../util/util";
import "../../styles.css";
const { Text, Title, Link } = Typography;
const { RangePicker } = DatePicker;

export const PendenciaConciliacao = () => {
  const [dataInical, setDataInicial] = useState<Dayjs | null>(
    dayjs().subtract(7, "d")
  );
  const [dataFinal, setDataFinal] = useState<Dayjs | null>(
    dayjs(new Date(), "DD-MM-YYYY")
  );

  const [pendencias, setPendenciaConciliacaoTDO] = useState<
    PendenciaConciliacaoTDO[]
  >([]);

  const [diasSemana, setDiasSemana] = useState<DiaSemana[]>([]);
  const [filter, setFilter] = useState();

  const [currentPosition, setPosition] = useState<number>(1);
  const [totalItens, setTotalItens] = useState(0);
  const [errosNaSemana, setErrosNaSemana] = useState(0);
  const [empresa, setEmpresa] = useState<Empresa>();

  const [limite, setLimite] = useState(5);
  const [loading, setLoading] = useState(false);
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const navigate = useNavigate();
  const { contrato } = useParams();
  const columns: ColumnsType<PendenciaConciliacaoTDO> = [

    {
      title: "Empresa",
      dataIndex: "empresa",
      key: "empresa",
    },
    {
      title: "Número Caixa",
      dataIndex: "numero_caixa",
      key: "numero_caixa",
    },
    {
      title: "Data Divergência",
      dataIndex: "data_divergencia",
      key: "data_divergencia",
      render: (text: string) => {
        return stringToDateWithHour(text);
      },
    },
    {
      title: "Qtd. NFCE Autorizado",
      dataIndex: "quantidade_nfce_autorizado",
      key: "quantidade_nfce_autorizado",
    },
    {
      title: "Qtd. NFE Cancelada",
      dataIndex: "quantidade_nfce_cancelada",
      key: "quantidade_nfce_cancelada",
    },
    {
      title: "Qtd. NFCE Sem XML",
      dataIndex: "quantidade_nfce_sem_xml",
      key: "quantidade_nfce_sem_xml",
    },
    {
      title: "Qtd. NFCE Divergente",
      dataIndex: "quantidade_nfce_diverg_status_doc_fiscal",
      key: "quantidade_nfce_diverg_status_doc_fiscal",
    },
    {
      title: "Salto Numeração",
      dataIndex: "qtdSaltosNumeracao",
      key: "qtdSaltosNumeracao",
    },
    {
      title: "Qtd. NFCE Ausente Tab. Saída",
      dataIndex: "qtd_nfce_ausente_tab_saida",
      key: "qtd_nfce_ausente_tab_saida",
    },
  ];

  useEffect(() => {
    setLoading(true);
    getPendenciasConciliacao(
      currentPosition,
      limite,
      dateStart ?? "",
      dateEnd ?? "",
      contrato ?? ""
    )
      .then((result) => {
        setPendenciaConciliacaoTDO(result?.data?.pendenciasConciliacoes);
        setErrosNaSemana(result?.data?.total);
        setTotalItens(result?.data?.total);
        setEmpresa(result?.data?.empresa)
        setLoading(false);
      })
      .then((error) => {
        setLoading(false);
      });
  }, [currentPosition, limite, dateStart, dateEnd]);

  const next = (numero: number) => {
    setPosition(currentPosition + numero);
  };
  //useEffect(() => {
  //getErros(currentPosition, limite, dataInical?.format("DD-MM-YYYY") ?? "", dataFinal?.format("DD-MM-YYYY") ?? "").then((result) => {
  //	setErroLog(result?.data?.erros)
  //	setErrosNaSemana(result?.data?.total)
  //	setTotalItens(result?.data?.total)
  //}).then((error) => {

  //})
  //getDias(dataInical?.format("DD-MM-YYYY") ?? "", dataFinal?.format("DD-MM-YYYY") ?? "").then((result) => {
  //	setDiasSemana(result?.data)
  // diasSemana.reverse();

  //	console.warn(result)
  //}).then((error) => {

  //})
  //}, [currentPosition, limite, dataFinal, dataInical]);
  const handleRangeChange = (dates: any) => {
    if (dates) {
      const startDate = dates[0];
      const endDate = dates[1];
      const formattedStartDate = startDate
        ? startDate.format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
      const formattedRange = `${formattedStartDate} - ${formattedEndDate}`;
      //setTextDataAgendamento(formattedRange);
      setDateStart(formattedStartDate);
      setDateEnd(formattedEndDate);
    } else {
      setDateStart("");
      setDateEnd("");
    }
  };
  const nextt = (numero: number) => {
    setPosition(currentPosition + numero);
  };
  const onChange: PaginationProps["onChange"] = (page) => {
    console.log(page);
    setPosition(page);
  };
  const data = [
    {
      ocorrencia: "Seg",
      uv: 4000,
      dia: 10,
      amt: 2400,
    },
    {
      ocorrencia: "Seg",
      uv: 4000,
      dia: 10,
      amt: 2400,
    },
  ];
  function selecionarPeriodo(periodo: string) {
    const periodoCriado = criarPeriodo(Number(periodo));
    setDataInicial(dayjs(periodoCriado.dataInicial?.toDate()));
    setDataFinal(dayjs(periodoCriado.dataFinal?.toDate()));
  }
  function stringToDate(date: string) {
    const currentDate = moment(date).format("ll");
    return currentDate;
  }

  return (
    <VStack w={"100%"} bg={"#F7FAFC"}>
      {/*
				<HStack w={"full"}  >
				<Select fontWeight={"semibold"} color={"gray.600"} bg={"white"} onChange={(e) => {
					selecionarPeriodo(e.target.value)
				}}>
					<option value='7'><Text fontSize={"md"} fontWeight={"semibold"}>Últimos 7 dias</Text></option>
					<option value='30'>Últimos d30 dias</option>
					<option value='180'>Últimos 180 dias</option>
					<option value='365'>Últimos 365 dias</option>
				</Select>
				<Spacer />
				<VStack w={"full"}>

					<ConfigProvider locale={ptBR}>
						<DatePicker format={"DD-MM-YYYY"} value={dataInical} defaultValue={dayjs(new Date(), 'DD-MM-YYYY').subtract(7, 'd')} onChange={(e) => { setDataInicial(e); console.log("momento", e?.format("YYYY-MM-DD HH:MM:SS")) }} placeholder="Data inicial" size="large" style={{ width: '100%', borderRadius: 4 }} />
					</ConfigProvider>
				</VStack>
				<VStack w={"full"}>
					<ConfigProvider locale={ptBR}>
						<DatePicker format={"DD-MM-YYYY"} defaultValue={dayjs(new Date(), 'DD-MM-YYYY')} onChange={(e) => { setDataFinal(e); }} placeholder="Data final" size="large" style={{ width: '100%', borderRadius: 4, fontWeight: 600, color: "gray" }} />
					</ConfigProvider>
				</VStack>
			</HStack>
			*/}

      <VStack px={2} py={4} alignItems={"start"} w="full">
      <Title level={3}>{empresa?.razao_social} ({empresa?.contrato})</Title>
        <HStack w={"full"}>
          <Text type="secondary">Pendência de Conciliação</Text>
        </HStack>
        <RangePicker
            format="DD-MM-YYYY"
            placeholder={["Data inicial", "Data final"]}
            onChange={handleRangeChange}
          />
      </VStack>
      <VStack bgColor={"white"} borderRadius={5} minH={400} w={"full"}>
        {/* <VStack w={"full"} h={"40px"} >
					<Select bg={"white"}>
						<option value='option1'>Últimos 7 dias</option>
						<option value='option2'>Últimos 30 dias</option>
						<option value='option3'>Option 3</option>
					</Select>
				</VStack> */}
        <>
          <Table
		   rowClassName={rowClassName}
            loading={loading}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  console.log(record?.id);
                  //http://localhost:3000/painel/colaborador/erro-logs/19
                  //navigate((record?.id).toString());
                },
              };
            }}
            pagination={false}
            size="small"
            style={{ width: "100%", cursor: "pointer" }}
            columns={columns}
            dataSource={pendencias}
          />
          ;
          <VStack my={4}>
            <Pagination
              total={totalItens}
              showTotal={(totalItens) => `Total de ${totalItens} itens`}
              defaultPageSize={limite}
              defaultCurrent={1}
              onChange={onChange}
              current={currentPosition}
              locale={{ items_per_page: "/ Página"}}
            />
          </VStack>
        </>
      </VStack>
    </VStack>
  );
};
