import { Button, Center, useDrawerContext } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useDrawerModalErrosLogs } from "../components/versoes/useDrawerModalErrosLogs";
export const Test = () => {

	const [fetch, setFetch] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const {showDrawerCriarModal, contextHolderDrawerCreateVersion, DrawerModal} = useDrawerModalErrosLogs(
		setFetch,
		fetch,
		openDrawer,
		setOpenDrawer
	);
  return (
    <>
      <Center mt={10}>
        <Button onClick={showDrawerCriarModal}>Abrir modal</Button>
      </Center>
	  {DrawerModal}
    </>
  );
};
