import { VStack } from "@chakra-ui/react";
import { PrincipalPage as MainPage } from "../components/principal_page/PrincipalPage";
import CardsDataProvider from "../components/principal_page/context/DadosCardContext";
import DadosListProvider from "../components/principal_page/context/ListMainContext";
import { EventosProvider } from "../context/EventosContext";
import { DadosUsuariosVisualizacao } from "../context/UsuariosVisualizacaoContext";
import { RecoilRoot } from "recoil";
import { HistoricoListagemPage } from "../components/principal_page/HistoricoListagemPage";
import ListHistoricoProvider from "../components/principal_page/context/ListHistoricoContext";

export const HistoricoPage = () => {
	return (
		<VStack>
			<RecoilRoot>
			<EventosProvider>
				<ListHistoricoProvider>
				<CardsDataProvider>
					<DadosUsuariosVisualizacao>
						<HistoricoListagemPage />
					</DadosUsuariosVisualizacao>
				</CardsDataProvider>
				</ListHistoricoProvider>
			</EventosProvider>
			</RecoilRoot>
		</VStack>
	);
}