import { Center, HStack, VStack } from "@chakra-ui/react";
import {
  DatePicker,
  Pagination,
  PaginationProps,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import "moment/locale/pt-br";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRejeicoesFiscais } from "../../api/api";
import { RejeicaoFiscalTDO } from "../../types/types";

import {
  reduceText,
  retornaCorDocumento,
  rowClassName,
  stringToDateWithHour,
} from "../../util/util";
import { NotFoundEstatistica } from "./NotFoundEstatistica";
import { criarPeriodo } from "./util";
import { Empresa } from "./types";
import { start } from "repl";
const { Text, Title, Link } = Typography;
const { RangePicker } = DatePicker;

export const RejeicaoFiscal = () => {
  const [dataInical, setDataInicial] = useState<Dayjs | null>(
    dayjs().subtract(7, "d")
  );
  const [dataFinal, setDataFinal] = useState<Dayjs | null>(
    dayjs(new Date(), "DD-MM-YYYY")
  );

  const [loading, setLoading] = useState(false);

  const [rejeicoesFiscais, setRejeicoesFiscais] = useState<RejeicaoFiscalTDO[]>(
    []
  );

  const [currentPosition, setPosition] = useState<number>(1);
  const [totalItens, setTotalItens] = useState(0);
  const [errosNaSemana, setErrosNaSemana] = useState(0);
  const [empresa, setEmpresa] = useState<Empresa>();
  const [limite, setLimite] = useState(5);
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');

  const navigate = useNavigate();

  const { contrato } = useParams();

  const dados: RejeicaoFiscalTDO[] = [
    {
      contrato: "0000004",
      id: "4",
      codigo_empresa: "54",
      codigo_rejeicao: "784",
      descricao_rejeicao: "Lorem ipsum lorem upsu anu lorem ipsum",
      data_ocorrencia: "13 de Set. 2022",
      documento_ocorrencia: "13 de Set. 2022",
      data_criacao_online: "13 de Set. 2022",
    },
    {
      contrato: "0000004",
      id: "4",
      codigo_empresa: "54",
      codigo_rejeicao: "784",
      descricao_rejeicao: "Lorem ipsum lorem upsu anu lorem ipsum",
      data_ocorrencia: "13 de Set. 2022",
      documento_ocorrencia: "13 de Set. 2022",
      data_criacao_online: "13 de Set. 2022",
    },
    {
      contrato: "0000004",
      id: "4",
      codigo_empresa: "54",
      codigo_rejeicao: "784",
      descricao_rejeicao: "Lorem ipsum lorem upsu anu lorem ipsum",
      data_ocorrencia: "13 de Set. 2022",
      documento_ocorrencia: "13 de Set. 2022",
      data_criacao_online: "13 de Set. 2022",
    },
  ];

  const columns: ColumnsType<RejeicaoFiscalTDO> = [

    {
      title: "Empresa",
      dataIndex: "empresa",
      key: "empresa",
    },
    {
      title: "Código Rejeição",
      dataIndex: "cod_rejeicao",
      key: "cod_rejeicao",
    },
    {
      title: "Descrição Rejeição",
      dataIndex: "descricao",
      key: "descricao",
      render: (text: string, object: RejeicaoFiscalTDO) => {
        return (
          <>
            <Tooltip placement="topLeft" title={text}>
              {reduceText(text, 30)}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Número Documento",
      dataIndex: "num_documento",
      key: "num_documento",
    },
    {
      title: "Série",
      dataIndex: "serie",
      key: "serie",
    },
    {
      title: "Documento",
      dataIndex: "tipodoc",
      key: "tipodoc",
      render: (text: string, object: RejeicaoFiscalTDO) => {
        const cor = retornaCorDocumento(text);
        return (
          <>
            <Tag style={{ width: "50px", textAlign: "center" }} color={cor}>
              {text}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Ocorrência",
      dataIndex: "dt_ocorrencia",
      key: "dt_ocorrencia",
      render: (text: string) => {
        return <>{stringToDateWithHour(text)}</>;
      },
    },
  ];

  const handleRangeChange = (dates: any) => {
    if (dates) {
      const startDate = dates[0];
      const endDate = dates[1];
      const formattedStartDate = startDate
        ? startDate.format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
      const formattedRange = `${formattedStartDate} - ${formattedEndDate}`;
      //setTextDataAgendamento(formattedRange);
      setDateStart(formattedStartDate);
      setDateEnd(formattedEndDate);
    } else {
      setDateStart("");
      setDateEnd("");
    }
  };
  useEffect(() => {
    setLoading(true);
    getRejeicoesFiscais(
      currentPosition,
      limite,
      dateStart,
      dateEnd,
      contrato ?? ""
    )
      .then((result) => {
        setRejeicoesFiscais(result?.data?.rejeicoesFiscais);
        setErrosNaSemana(result?.data?.total);
        setTotalItens(result?.data?.total);
        setEmpresa(result?.data?.empresa)
        setLoading(false);
      })
      .then((error) => {
        setLoading(false);
      });
  }, [currentPosition, limite, dateStart, dateEnd]);
  const next = (numero: number) => {
    setPosition(currentPosition + numero);
  };

  const data = [
    {
      ocorrencia: "Seg",
      uv: 4000,
      dia: 10,
      amt: 2400,
    },
    {
      ocorrencia: "Seg",
      uv: 4000,
      dia: 10,
      amt: 2400,
    },
  ];
  function selecionarPeriodo(periodo: string) {
    const periodoCriado = criarPeriodo(Number(periodo));
    setDataInicial(dayjs(periodoCriado.dataInicial?.toDate()));
    setDataFinal(dayjs(periodoCriado.dataFinal?.toDate()));
  }
  const onChange: PaginationProps["onChange"] = (page) => {
    console.log(page);
    setPosition(page);
  };
  function stringToDate(date: string) {
    const currentDate = moment(date).format("ll");
    return currentDate;
  }

  return (
    <VStack w={"100%"}>
      {/*
			<HStack w={"full"}  >
				<Select fontWeight={"semibold"} color={"gray.600"} bg={"white"} onChange={(e) => {
					selecionarPeriodo(e.target.value)
				}}>
					<option value='7'><Text >Últimos 7 dias</Text></option>
					<option value='30'>Últimos 30 dias</option>
					<option value='180'>Últimos 180 dias</option>
					<option value='365'>Últimos 365 dias</option>
				</Select>
				<Spacer />
				<VStack w={"full"}>

					<ConfigProvider locale={ptBR}>
						<DatePicker format={"DD-MM-YYYY"} value={dataInical} defaultValue={dayjs(new Date(), 'DD-MM-YYYY').subtract(7, 'd')} onChange={(e) => { setDataInicial(e);  }} placeholder="Data inicial" size="large" style={{ width: '100%', borderRadius: 4 }} />
					</ConfigProvider>
				</VStack>
				<VStack w={"full"}>
					<ConfigProvider locale={ptBR}>
						<DatePicker format={"DD-MM-YYYY"} defaultValue={dayjs(new Date(), 'DD-MM-YYYY')} onChange={(e) => { setDataFinal(e); }} placeholder="Data final" size="large" style={{ width: '100%', borderRadius: 4, fontWeight: 600, color: "gray" }} />
					</ConfigProvider>
				</VStack>
			</HStack>
		*/}
      <VStack px={2} py={4} alignItems={"start"} w="full">
      <Title level={3}>{empresa?.razao_social} ({empresa?.contrato})</Title>
        <HStack w={"full"}>
          <Text type="secondary">Rejeições Fiscais</Text>
        </HStack>
        <RangePicker
            format="DD-MM-YYYY"
            placeholder={["Data agendamento", "Data agendamento"]}
            onChange={handleRangeChange}
          />
      </VStack>

      <VStack bgColor={"#F7FAFC"} borderRadius={5} minH={400} w={"full"}>
        {/* <VStack w={"full"} h={"40px"} >
					<Select bg={"white"}>
						<option value='option1'>Últimos 7 dias</option>
						<option value='option2'>Últimos 30 dias</option>
						<option value='option3'>Option 3</option>
					</Select>
				</VStack> */}
        <>
          <Table
            loading={loading}
            rowClassName={rowClassName}
            scroll={{ x: "max-content" }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  console.log(record?.id);
                  //http://localhost:3000/painel/colaborador/erro-logs/19
                  navigate((record?.id).toString());
                },
              };
            }}
            pagination={false}
            size="small"
            style={{ width: "100%", cursor: "pointer" }}
            columns={columns}
            dataSource={rejeicoesFiscais}
          />
          <VStack my={4}>
            <Pagination
              total={totalItens}
              showTotal={(totalItens) => `Total de ${totalItens} itens`}
              defaultPageSize={limite}
              defaultCurrent={1}
              onChange={onChange}
              current={currentPosition}
              locale={{ items_per_page: "/ Página"}}
            />
          </VStack>
        </>
      </VStack>
    </VStack>
  );
};
