import { VStack } from "@chakra-ui/react";
import { PrincipalPage as MainPage } from "../components/principal_page/PrincipalPage";
import CardsDataProvider from "../components/principal_page/context/DadosCardContext";
import DadosListProvider from "../components/principal_page/context/ListMainContext";
import { EventosProvider } from "../context/EventosContext";
import { DadosUsuariosVisualizacao } from "../context/UsuariosVisualizacaoContext";
import { RecoilRoot } from "recoil";

export const PrincipalPage = () => {
	return (
		<VStack>
			<RecoilRoot>
			<EventosProvider>
				<DadosListProvider>
				<CardsDataProvider>
					<DadosUsuariosVisualizacao>
						<MainPage />
					</DadosUsuariosVisualizacao>
				</CardsDataProvider>
				</DadosListProvider>
			</EventosProvider>
			</RecoilRoot>
		</VStack>
	);
}