import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ConfigProvider,
  DatePicker,
  Table,
  Tag,
  Select,
  Input,
  Pagination,
  PaginationProps,
  Tooltip,
  Typography,
  TableProps,
} from "antd";

import "../../styles.css";

import ptBR from "antd/es/locale/pt_BR";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getGerenciamentoAtualizacoesClientesDados } from "../../api/api";
import {
  ControleAtualizacaoColumnName,
  ModconColumnName,
} from "../../enuns/enuns";
import DoubleLeft from "../../images/double-left-arrow.png";

import type {
  ColumnsType,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/es/table/interface";
import DoubleRigth from "../../images/double-rigth-arrow.png";
import {
  eMatriz,
  reduceText,
  rowClassName,
  stringToDateWithHour,
} from "../../util/util";
import { CardsDataContext } from "../principal_page/context/DadosCardContext";
import { useRecoilState } from "recoil";
import { pracaAtom } from "../../atom/formFiltroPrincipal";
import moment from "moment";
import pt_BR from "antd/locale/pt_BR";

const { Text: TextAntD, Title, Link } = Typography;
const { Option } = Select;
interface DataType {
  id: string;
  contrato: string;
  codigo_versao: string;
  codigo_maquina: string;
  codigo_usuario_success: string;
  nome_usuario_success: string;
  data_atualizacao: string;
  data_agendada: string;
  ultimo_dia_trabalhado:string
  status_atualizacao: number;
  status_execucao: number;
  nomecli: string;
}

const { RangePicker } = DatePicker;

export const GerenciamentoAtualizacaoCliente = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataAgendadaInicial, setDataAgendadaInicial] = useState("");
  const [dataAgendadaFinal, setDataAgendadaFinal] = useState("");
  const [dataAtualizacaoInicial, setDataAtualizacaoInicial] = useState("");
  const [dataAtualizacaoFinal, setDataAtualizacaoFinal] = useState("");
  const [contrato, setContrato] = useState("");
  const [codigoVersao, setCodigoVersao] = useState("");
  const [statusExecucao, setStatusExecucao] = useState("");
  const [statusAtualizacao, setStatusAtualizacao] = useState("");

  const [textPraca, setTextPraca] = useRecoilState(pracaAtom);

  const { user } = useContext(CardsDataContext);


  const [especie, setEspecie] = useState("");
  const [situacaoModulo, setSituacaoModulo] = useState("");

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const navigate = useNavigate();
  const [currentPosition, setPosition] = useState<number>(1);
  const [totalItens, setTotalItens] = useState(0);
  const [limite, setLimite] = useState(10);

  const [dados, setDados] = useState<DataType[]>([]);

  const [loading, setLoading] = useState(false);

  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>>({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});

  const [allErros, setAllErros] = useState<DataType[]>([]);
  const [paginatedData, setPaginatedData] = useState<DataType[]>([]);

  const next = (numero: number) => {
    setPosition(currentPosition + numero);
  };

  // @ts-ignore
  useEffect(() => {
    setLoading(true);
    getGerenciamentoAtualizacoesClientesDados(
      currentPosition,
      limite,
      contrato,
      codigoVersao,
      Number(statusExecucao),
      dataAgendadaInicial,
      dataAgendadaFinal,
      dataAtualizacaoInicial,
      dataAtualizacaoFinal,
      Number(statusAtualizacao),
      Number(especie),
      Number(situacaoModulo),
      textPraca
    ).then((result) => {
      setTotalItens(result?.data?.total);
      setAllErros(result?.data?.dados || []);
      setLoading(false);
    });
  }, [
    codigoVersao,
    contrato,
    currentPosition,
    dataAgendadaInicial,
    dataAgendadaFinal,
    dataAtualizacaoInicial,
    limite,
    statusAtualizacao,
    statusExecucao,
    dataAtualizacaoFinal,
    situacaoModulo,
    especie,
    textPraca
  ]);

  // Função de ordenação personalizada
const customSort = (columnKey: string | number | undefined) => (a: DataType, b: DataType) => {
  const valueA = a[columnKey as keyof DataType];
  const valueB = b[columnKey as keyof DataType];

  if (columnKey === ControleAtualizacaoColumnName.dataAtualizacao) {
    const dateA = moment(valueA);
    const dateB = moment(valueB);

    return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
  }

  if (valueA === undefined || valueB === undefined) {
    return 0; 
  }

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return valueA.localeCompare(valueB);
  }

  return (valueA as number) - (valueB as number);
};

// Atualiza a ordenação dos dados quando há mudança
useEffect(() => {
  let sortedData = [...allErros];
  if ('columnKey' in sortedInfo && sortedInfo.columnKey) {
    sortedData = sortedData.sort(
      sortedInfo.order === 'ascend'
        ? customSort(sortedInfo.columnKey)
        : (a, b) => customSort(sortedInfo.columnKey)(b, a)
    );
  }
  setPaginatedData(sortedData.slice((currentPosition - 1) * limite, currentPosition * limite));
}, [allErros, currentPosition, limite, sortedInfo]);

// Função para lidar com a mudança de ordenação, filtro e paginação
const handleChange: TableProps<DataType>["onChange"] = (
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<DataType> | SorterResult<DataType>[]
) => {
  setFilteredInfo(filters);

  if ('columnKey' in sorter && sorter.columnKey) {
    setSortedInfo(sorter as SorterResult<DataType>);
    setPosition(1);
  }
};

// Função para lidar com a mudança de filtro
const handleFIlter = () => {
  setPosition(1);
};


  const columns: ColumnsType<DataType> = [
    {
      title: "Contrato",
      dataIndex: ControleAtualizacaoColumnName.contrato,
      key: ControleAtualizacaoColumnName.contrato,
      className: 'contrato-column',
      fixed: "left",
      showSorterTooltip: false,
      sorter: (a: DataType, b: DataType) =>
        Number(a.contrato) - Number(b.contrato),
      sortOrder: sortedInfo.columnKey === "contrato" ? sortedInfo.order : null,
    },
    {
      title: "Razão Social",
      dataIndex: ModconColumnName.razaoSocial,
      key: ModconColumnName.razaoSocial,
      width: "300px",
      fixed: "left",
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => record.toString().includes(value.toString()),
      sorter: (a, b) => {
        const razaoA = a.nomecli || ""; // Se for nulo, trate como uma string vazia
        const razaoB = b.nomecli || ""; // Se for nulo, trate como uma string vazia
        return razaoA.localeCompare(razaoB);
      },
      sortOrder: sortedInfo.columnKey === "nomecli" ? sortedInfo.order : null,
      showSorterTooltip: false,
      render: (text: string) => {
        return (
          <>
            <Tooltip title={text}>
              <a>{reduceText(text, 30)}</a>
            </Tooltip>
          </>
        );
      }
    },
      {
        title: "Tipo Instalação",
        dataIndex: "especie",
        align: "left",
        key: "especie",
        render: (text: string) => {
          let status = "";
          let color = "";
          if (Number(text) === 1) {
            status = "1 - Venda";
            color = "#bfbfbf";
          }
          if (Number(text) === 2) {
            status = "2 - Atualização Gratuita";
            color = "#bfbfbf";
          }
          if (Number(text) === 3) {
            status = "3 - Alteração";
            color = "#bfbfbf";
          }
          if (Number(text) === 4) {
            status = "4 - Comodato";
            color = "#bfbfbf";
          }
          if (Number(text) === 5) {
            status = "5 - Demonstração";
            color = "#ff4d4f";
          }
          if (Number(text) === 6) {
            status = "6 - Reativação";
            color = "#bfbfbf";
          }
          if (text) {
            if(Number(text) === 5){
              return (
                <Tooltip title={status}>
                <Tag style={{ width: "100px", textAlign: "left", color: "black", fontWeight: 'semibold'}} color={color}>
                {reduceText(status, 14)}
                </Tag>
              </Tooltip>
               
              );
            }else{
              return (
                <Tooltip title={status}>
                <Tag style={{ width: "100px", textAlign: "left", color: "black" }} color={color}>
                {reduceText(status, 14)}
                </Tag>
              </Tooltip>
               
              );
            }
          } else {
            <></>;
          }
        },
      },
      {
        title: "Situação Módulo",
        dataIndex: "situacao_modulo",
        key: "situacao_modulo",
       
        render: (text: string) => {
          let status = "";
          let color = "";
          if (Number(text) === 1) {
            status = "1 - Cliente não cadastrado";
            color = "#bfbfbf";
          }
          if (Number(text) === 2) {
            status = "2 - Ficha Bloqueada";
            color = "#bfbfbf";
          }
          if (Number(text) === 3) {
            status = "3 - Manutenção não Cadastrada";
            color = "#bfbfbf";
          }
          if (Number(text) === 4) {
            status = "4 - Manutenção c/Impedimento";
            color = "#bfbfbf";
          }
          if (Number(text) === 5) {
            status = "5 - Divergência Valor Manutenção";
            color = "#bfbfbf";
          }
  
          if (text) {
            return (
              <Tooltip title={status}>
              <Tag style={{ textAlign: "left", color: "black" }} color={color}>
              {reduceText(status, 14)}
              </Tag>
            </Tooltip>
             
            );
          } else {
            <></>;
          }
        },
      },
    {
      title: "Código Versão",
      dataIndex: ControleAtualizacaoColumnName.codigoVersao,
      key: ControleAtualizacaoColumnName.codigoVersao,
    },
    {
      title: "Código Máquina",
      dataIndex: ControleAtualizacaoColumnName.codigoMaquina,
      key: ControleAtualizacaoColumnName.codigoMaquina,
    },

    {
      title: "Data Atualização",
      dataIndex: ControleAtualizacaoColumnName.dataAtualizacao,
      key: ControleAtualizacaoColumnName.dataAtualizacao,
      render: (text: string, obj: DataType) => <a>{stringToDateWithHour(text)}</a>,
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === ControleAtualizacaoColumnName.dataAtualizacao
          ? sortedInfo.order
          : null,
      showSorterTooltip: false,
    },
    {
      title: "Último Dia Trabalhado",
      dataIndex: "ultimo_dia_trabalhando",
      key: "ultimo_dia_trabalhando",
      render: (text: string, obj: DataType) => <a>{stringToDateWithHour(text)}</a>,
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === 'ultimo_dia_trabalhando'
          ? sortedInfo.order
          : null,
      showSorterTooltip: false,
    },
    
    {
      title: "Data Agendada",
      dataIndex: ControleAtualizacaoColumnName.dataAgendamento,
      key: ControleAtualizacaoColumnName.dataAgendamento,
      render: (text: string) => {
        //  return <a>{stringToDateWithHour(text)}</a>
        return <a>{}</a>;
      },
    },
    {
      title: "Status Atualização",
      dataIndex: ControleAtualizacaoColumnName.statusAtualizacao,
      key: ControleAtualizacaoColumnName.statusAtualizacao,
      render: (text: string) => {
        let status = "";
        let color = "";
        if (Number(text) === 1) {
          status = "Atualizado";
          color = "#87d068";
        }
        if (Number(text) === 2) {
          status = "Ag. Atualização";
          color = "#d0cb68";
        }
        if (Number(text) === 3) {
          status = "Agu. Intervenção";
          color = "#d09368";
        }
        if (Number(text) === 4) {
          status = "Desatualizado";
          color = "#d06868";
        }
        return (
          <Tag style={{ width: "100px", textAlign: "center" }} color={color}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Status Execução",
      dataIndex: ControleAtualizacaoColumnName.statusExecucao,
      key: ControleAtualizacaoColumnName.statusExecucao,
      render: (text: string) => {
        let status = "";
        let color = "";
        if (Number(text) === 1) {
          status = "Manual";
          color = "#d09368";
        }
        if (Number(text) === 2) {
          status = "Automático";
          color = "#87d068";
        }
        return (
          <Tag style={{ width: "70px", textAlign: "center" }} color={color}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Código Usuário",
      dataIndex: ControleAtualizacaoColumnName.codigoUsuarioSuccess,
      key: ControleAtualizacaoColumnName.codigoUsuarioSuccess,
    },
    {
      title: "Nome Usuário",
      dataIndex: ControleAtualizacaoColumnName.nomeUsuarioSuccess,
      key: ControleAtualizacaoColumnName.nomeUsuarioSuccess,
    },
  ];

  const handleRangeChangePeriodoDataAgendamento = (dates: any) => {
    if (dates) {
      const startDate = dates[0];
      const endDate = dates[1];
      const formattedStartDate = startDate
        ? startDate.format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
      //setTextDataAgendamento(formattedRange);
      setDataAgendadaInicial(formattedStartDate);
      setDataAgendadaFinal(formattedEndDate);
    } else {
      setDataAgendadaInicial("");
      setDataAgendadaFinal("");
    }
  };
  const handleRangeChangePeriodoDataAtualizacao = (dates: any) => {
    if (dates) {
      const startDate = dates[0];
      const endDate = dates[1];
      const formattedStartDate = startDate
        ? startDate.format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
      //setTextDataAgendamento(formattedRange);
      setDataAtualizacaoInicial(formattedStartDate);
      setDataAtualizacaoFinal(formattedEndDate);
    } else {
      setDataAtualizacaoInicial("");
      setDataAtualizacaoFinal("");
    }
  };
  const onChange: PaginationProps["onChange"] = (page) => {
    setPosition(page);
  };
  return (
    <>
      <VStack w={"full"}>
        <HStack spacing={5} w="full"></HStack>
        <VStack overflow={"auto"} borderRadius={5} minH={400} w={"full"}>
          <VStack pr={5} alignItems={"start"} w="full">
            <Title level={3}>Controle de Atualizações</Title>
            <HStack w={"full"} pt={5}>
              <FormControl>
                <FormLabel>Contrato</FormLabel>
                <Input
                  type={"text"}
                  value={contrato}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setContrato(e.target.value);
                    handleFIlter();
                  }}
                  placeholder={"Contrato / Razão Social"}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Código versão </FormLabel>
                <Input
                  value={codigoVersao}
                  type={"text"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCodigoVersao(e.target.value);
                    handleFIlter();
                  }}
                  placeholder={"ex: 054"}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Status execução </FormLabel>
                <Select
                  defaultValue={"0"}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setStatusExecucao(e);
                    handleFIlter();
                  }}
                >
                  <Option value="0">Selecione</Option>
                  <Option value="1">Manual</Option>
                  <Option value="2">Automático</Option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Data Agendada </FormLabel>
                <VStack w={"full"}>
                  <ConfigProvider locale={ptBR}>
                    <RangePicker
                      style={{ width: "100%" }}
                      format="DD-MM-YYYY"
                      placeholder={["Data agendamento", "Data agendamento"]}
                      onChange={handleRangeChangePeriodoDataAgendamento}
                    />
                  </ConfigProvider>
                </VStack>
              </FormControl>
              <FormControl>
                <FormLabel>Data Atualização </FormLabel>
                <VStack w={"full"}>
                  <ConfigProvider locale={ptBR}>
                    <RangePicker
                      style={{ width: "100%" }}
                      format="DD-MM-YYYY"
                      placeholder={["Data Atualização", "Data Atualização"]}
                      onChange={handleRangeChangePeriodoDataAtualizacao}
                    />
                  </ConfigProvider>
                </VStack>
              </FormControl>
              <FormControl>
                <FormLabel>Status atualização </FormLabel>
                <Select
                  defaultValue={"0"}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setStatusAtualizacao(e);
                    handleFIlter();
                  }}
                >
                  <Option value="0">Selecione</Option>
                  <Option value="1">Atualizado</Option>
                  <Option value="2">Agendado atualização</Option>
                  <Option value="3">Baixado e aguardando intervenção</Option>
                  <Option value="4">Desatualizado</Option>
                </Select>
              </FormControl>
            </HStack>
            <HStack>
              <VStack alignItems={"start"} w={"full"}>
                <FormLabel marginBottom={0}>Tipo Instalação</FormLabel>
                <Select
                  style={{ width: "100%" }}
                  value={especie}
                  onChange={(e) => {
                    setEspecie(e);
                    handleFIlter();
                  }}
                  placeholder="Por favor insira a praça desejada"
                >
                  <Option value="">Selecione</Option>
                  <Option value="1">Venda</Option>
                  <Option value="2">Atualização Gratuita</Option>
                  <Option value="3">Alteração</Option>
                  <Option value="4">Comodato</Option>
                  <Option value="5">Demonstração</Option>
                  <Option value="6">Reativação</Option>
                </Select>
              </VStack>
              <VStack w={300} alignItems={"start"}>
                <FormLabel marginBottom={0}>Sit. Módulo</FormLabel>
                <Select
                  style={{ width: "100%" }}
                  value={situacaoModulo}
                  onChange={(e) => {
                    setSituacaoModulo(e);
                    handleFIlter();
                  }}
                  placeholder="Por favor insira a praça desejada"
                >
                  <Option value="">Selecione</Option>
                  <Option value="1">Cliente não cadastrado</Option>
                  <Option value="2">Ficha Bloqueada</Option>
                  <Option value="3">Manutenção não Cadastrada</Option>
                  <Option value="4">Manutenção c/Impedimento</Option>
                  <Option value="5">Divergência Valor Manutenção</Option>
                </Select>
              </VStack>
              {eMatriz(user?.praca) && (
            <VStack alignItems={"start"} w={"full"}>
              <FormLabel marginBottom={0}>Praça</FormLabel>
              <Select
                style={{ width: "100%" }}
                value={textPraca}
                onChange={(e) => {
                  setTextPraca(e);
                }}
                placeholder="Por favor insira a praça desejada"
              >
                <Option value="-1">Selecione</Option>
                <Option value="MA000001">PARACATU</Option>
                <Option value="MA000003">UNAI</Option>
                <Option value="MA000004">BRASILIA</Option>
                <Option value="MA000005">BRASILANDIA DE MINAS</Option>
                <Option value="MA000006">JOÃO PINHEIRO</Option>
                <Option value="MA000008">ARAXA</Option>
                <Option value="MA000009">VAZANTE</Option>
                <Option value="MA000013">CRISTALINA</Option>
                <Option value="MA000028">TRÊS MARIAS</Option>
                <Option value="MA000031">MATRIZ</Option>
              </Select>
            </VStack>
          )}
            </HStack>
          </VStack>
          <Table
            rowClassName={rowClassName}
            onChange={handleChange}
            scroll={{ x: 1800 }}
            loading={loading}
            size="small"
            style={{ width: "100%" }}
            pagination={false}
            columns={columns}
            dataSource={paginatedData}
          />
          ;
        </VStack>
        <VStack my={4}>
          <Pagination
            total={totalItens}
            showTotal={(total) => `Total de ${total} itens`}
            defaultPageSize={10}
            defaultCurrent={1}
            onShowSizeChange={(current, size) => setLimite(size)}
            onChange={onChange}
            current={currentPosition}
            locale={{ items_per_page: "/ Página"}}
          />
        </VStack>
      </VStack>
      <Modal
        size={"lg"}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Criar versão de produção</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack overflow={"auto"} maxH={300}>
              <FormControl>
                <FormLabel>Título</FormLabel>
                <Input ref={initialRef} placeholder="Versão x-yz" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Versão</FormLabel>
                <Input placeholder="Last name" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Descrição</FormLabel>
                <Input placeholder="Last name" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Nome do arquivo</FormLabel>
                <Input placeholder="Last name" />
              </FormControl>
              <FormControl>
                <FormLabel>Descrição</FormLabel>
                <Textarea placeholder="Descreva a versão" size="sm" />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>
                  Por favor, digite "eu confirmo que desejo lançar essa versão"
                </FormLabel>
                <Input placeholder="" />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3}>
              Lançar
            </Button>
            <Button onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
