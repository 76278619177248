import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Progress,
  Row,
  Select,
  Select as SelectAnt,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { criarVersao } from "../../api/api";
import { UseMessageApi } from "../../message/UseMessageApi";
import "../versoes/versoes.css";
import { DataType } from "./Versoes";
import { HStack, Image, VStack } from "@chakra-ui/react";
// import { DropzoneRootProps, useDropzone } from "react-dropzone"; // Comentei esta linha
import Upload from "../../img/opcao-de-upload-de-armazenamento-em-nuvem.png";
import Blank from "../../img/blank-page.png";
import { formatBytes } from "../../util/util";
import axios from "axios";
const { Text: Text, Title, Link } = Typography;
const { Option } = SelectAnt;

export const useDrawerModalCreateVersion = (
  setFetch: React.Dispatch<React.SetStateAction<boolean>>,
  fetch: boolean,
  openDrawer: boolean,
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [form] = Form.useForm();

  const [descricao, setDescricao] = useState("");
  const [nomeArquivo, setNomeArquivo] = useState("jjhd.php");
  const [statusExecucao, setStatusExecucao] = useState("0");
  const [codigo, setCodigo] = useState("dddd");
  const [versao, setVersao] = useState("");
  const [todosCamposPreenchidos, setTodosCamposPreenchidos] = useState(false);
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [tempoMedioAtualizacao, setTempoMedioAtualizacao] = useState(0);
  const [loading, setLoading] = useState(false);

  //messages
  const { contextHolder, error, success, warning } = UseMessageApi();
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [file, setFile] = useState<any>(null);
  const [type, setType] = useState<string>();
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  // Comentado o uso de react-dropzone
  // const { getRootProps, getInputProps, isDragActive }: DropzoneRootProps =
  //   useDropzone({ onDrop });

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setFileName(file.name);
      setFileSize(String(file.size));
      setType(file.type);
      setFile(file);
      setShowProgress(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setShowProgress(!showProgress);
    const data = {
      codigo: fileName?.toUpperCase(),
      nome_arquivo: fileName?.toUpperCase(),
      tempo_medio_atualizacao: tempoMedioAtualizacao,
      status_execucao: statusExecucao,
      descricao,
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify(data));

    try {
      const response = await axios.post(
        "https://apiatualizacaoautomaticaproducao.successsistemas.com/controle-versao/upload",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = progressEvent.total
              ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
              : 0;
            setProgress(percentCompleted);
            if (percentCompleted >= 100) {
              success("Upload Efetuado com sucesso!");
              setShowProgress(!showProgress);
              clear();
              setLoading(false);
            }
          },
        }
      ).then((response) =>{
        setFetch(!fetch);
      });
    } catch (error) {
      console.error(error);
      setShowProgress(!showProgress);
      setLoading(false);
    }
  };

  const clear = () => {
    setFileName("");
    setTempoMedioAtualizacao(0);
    setStatusExecucao(String(0));
    setDescricao("");
    setShowProgress(false);
  };

  const validarCampos = () => {
    if (codigo.length > 1) {
      if (descricao.length > 5) {
        if (tempoMedioAtualizacao > 0) {
          if (nomeArquivo.length > 5 || true) {
            if (descricao.length > 5) {
              setNomeArquivo(nomeArquivo?.toUpperCase())
              handleSubmit();
            } else {
              warning("Campo descrição precisa ter pelo menos 5 caractere.");
            }
          } else {
            warning("Campo Nome do arquivo precisa ser preenchido.");
          }
        } else {
          warning("Campo Tempo médio atualização precisa ser maior que 0.");
        }
      } else {
        warning("Campo descrição precisa ter pelo menos 5 caractere.");
      }
    } else {
      warning("Campo código precisa ter pelo menos 1 caractere.");
    }
  }
  const showDrawerCriarModal = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawerModal = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {}, []);

  const handleUpload = () => {
    if (file) {
      setCodigo("e");
    }
  };

// No componente UploadComponenteReturn
const UploadComponenteReturn = (
  <VStack
    cursor={"pointer"}
    w={"full"}
    bg={"#fafafa"}
    borderColor={"#f1f1f1"}
    borderRadius={5}
    borderWidth={"1.8px"}
    paddingY={5}
  >
    {/* Adicione um id ao input */}
    <input
      id="upload-file-input"
      type="file"
      onChange={onFileChange}
      style={{ display: 'none' }}
      accept=".jpg, .jpeg, .png, .pdf, .zip, .rar" // Adicione aqui os tipos de arquivo aceitos
    />
    <Image width={"48px"} src={Upload} />
    {/* Use htmlFor para associar o label ao input */}
    <label htmlFor="upload-file-input">Click aqui para selecionar um arquivo</label>
    <Text type="secondary">
      {fileName
        ? `${fileName} ${formatBytes(Number(fileSize))}`
        : "Suporte para apenas 1 upload por vez"}
    </Text>
    {showProgress && (
      <Progress
        style={{ paddingLeft: "10px", paddingRight: "10px" }}
        percent={progress}
        size="small"
      />
    )}
  </VStack>
);


  const DrawerModal = (
    <>
      {contextHolder}
      <Drawer
        title="Criar uma nova versão"
        width={720}
        onClose={onCloseDrawerModal}
        open={openDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button disabled={loading} onClick={onCloseDrawerModal}>Cancelar</Button>
            <Button loading={loading} type="primary" onClick={validarCampos}>
              Criar
            </Button>
          </Space>
        }
      >
        <VStack w={"full"} spacing={5}>
          <HStack w={"full"}>
            <VStack alignItems={"start"} w={"full"}>
              <Text>Código</Text>
              <Input disabled value={fileName} />
            </VStack>
            <VStack alignItems={"start"} w={"full"}>
              <Text>Tempo Médio atualização</Text>
              <Input
                value={tempoMedioAtualizacao}
                onChange={(e) => {
                  setTempoMedioAtualizacao(Number(e.target.value));
                }}
              />
            </VStack>
          </HStack>
          <VStack alignItems={"start"} w={"full"}>
            <Text>Status de execução</Text>
            <Select
              style={{ width: "100%" }}
              defaultValue={statusExecucao}
              onChange={(e) => {
                setStatusExecucao(e);
              }}
              placeholder="Por favor insira o status de execução"
            >
              <Option value="0">Automático</Option>
              <Option value="1">Manual</Option>
            </Select>
          </VStack>
          <HStack w={"full"}>
            <VStack alignItems={"start"} w={"full"}>
              <Text>Descrição</Text>
              <Input.TextArea
                value={descricao}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setDescricao(e.target.value);
                }}
                rows={2}
                placeholder="Por favor insira a descrição"
              />
            </VStack>
          </HStack>
          {UploadComponenteReturn}
        </VStack>
      </Drawer>
    </>
  );



  return {
    DrawerModal,
    showDrawerCriarModal,
    contextHolderDrawerCreateVersion: contextHolder,
  };
};
