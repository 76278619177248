import { HStack, Stack, VStack } from "@chakra-ui/react";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Drawer,
  Form,
  Input,
  Select,
  Select as SelectAnt,
  Space,
  Typography,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { DropzoneRootProps, useDropzone } from "react-dropzone";
import { useRecoilState } from "recoil";
import moment from "moment";
import ptBR from "antd/es/locale/pt_BR";
import {
  agendamentoDataFinal,
  agendamentoDataInicial,
  atualizacaoDataFinal,
  atualizacaoDataInicial,
  codigoRejeicao,
  contratoAtom,
  dataAgendamento,
  dataAtualizacao,
  dataErroLogEnd,
  dataErroLogStart,
  dataOcorrenciaEnd,
  dataOcorrenciaStart,
  dataPendenciaEnd,
  dataPendenciaStart,
  erroLog,
  especieAtom,
  modeloDocumento,
  pendenciaConciliacao,
  pracaAtom,
  rejeicaoFiscal,
  situacaoModulo,
  statusAtualizacaoAtom,
  statusExecucao,
  versaoEmUsoAtom,
} from "../../atom/formFiltroPrincipal";
import { UseMessageApi } from "../../message/UseMessageApi";
import { DataType } from "../versoes/Versoes";
import "../versoes/versoes.css";
import { FiltroCheckers } from "./FiltroCheckers";
import { CardsDataContext } from "./context/DadosCardContext";
import { eMatriz } from "../../util/util";
const { Text: Text, Title, Link } = Typography;
const { Option } = SelectAnt;
const { RangePicker } = DatePicker;
export const useDrawerModalFiltroPrincipal = (
  setFetch: React.Dispatch<React.SetStateAction<boolean>>,
  fetch: boolean,
  openDrawer: boolean,
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [form] = Form.useForm();

  //
  const [textContrato, setTextContrato] = useRecoilState(contratoAtom);
  const [textVersaoEmUso, setTextVersaoEmUso] = useRecoilState(versaoEmUsoAtom);

  const { user } = useContext(CardsDataContext);
  console.clear();
  console.log("=====================DADOS==============");
  console.log(eMatriz(user?.praca));

  const [textStatusExecucao, setTextStatusExecucao] =
    useRecoilState(statusExecucao);
  const [textStatusAtualizacao, setTextStatusAtualizacao] = useRecoilState(
    statusAtualizacaoAtom
  );
  const [textPraca, setTextPraca] = useRecoilState(pracaAtom);
  const [textDataAgendamento, setTextDataAgendamento] =
    useRecoilState(dataAgendamento);
  const [textDataAtualizacao, setTextDataAtualizacao] =
    useRecoilState(dataAtualizacao);

  const [textErroLog, setTextErroLog] = useRecoilState(erroLog);
  const [textPendenciaConciliacao, setTextPendenciaConciliacao] =
    useRecoilState(pendenciaConciliacao);
  const [textRejeicoesFiscais, setTextRejeicoesFiscais] =
    useRecoilState(rejeicaoFiscal);
  const [textCodigoRejeicao, setTextCodigoRejeicao] =
    useRecoilState(codigoRejeicao);
  const [textModeloDocumento, setTextModeloDocumento] =
    useRecoilState(modeloDocumento);
  const [textDataOcorrenciaStart, setTextDataOcorrenciaStart] =
    useRecoilState(dataOcorrenciaStart);
  const [textDataOcorrenciaFinal, setTextDataOcorrenciaFinal] =
    useRecoilState(dataOcorrenciaEnd);

  const [textDataErroLogStart, setTextDataErroLogStart] =
    useRecoilState(dataErroLogStart);
  const [textDataErroLogEnd, setTextDataErroLogEnd] =
    useRecoilState(dataErroLogEnd);

  const [textDataPendenciaStart, setTextDataPendenciaStart] =
    useRecoilState(dataPendenciaStart);
  const [textDataPendenciaEnd, setTextDataPendenciaEnd] =
    useRecoilState(dataPendenciaEnd);

  const [textAgendamentoDataInicial, setTextAgendamentoDataInicial] =
    useRecoilState(agendamentoDataInicial);
  const [textAgendamentoDataFinal, setTextAgendamentoDataFinal] =
    useRecoilState(agendamentoDataFinal);

  const [textAtualizacaoDataInicial, setTextAtualizacaoDataInicial] =
    useRecoilState(atualizacaoDataInicial);
  const [textAtualizacaoDataFinal, setTextAtualizacaoDataFinal] =
    useRecoilState(atualizacaoDataFinal);

  const [textEspecie, setTextEspecie] = useRecoilState(especieAtom);
  const [textSituacaoModulo, setTextSituacaoModulo] =
    useRecoilState(situacaoModulo);

  const [descricao, setDescricao] = useState("");
  const [nomeArquivo, setNomeArquivo] = useState("jjhd.php");

  const [codigo, setCodigo] = useState("dddd");
  const [tempoMedioAtualizacao, setTempoMedioAtualizacao] = useState(0);
  const [loading, setLoading] = useState(false);

  //messages
  const { contextHolder, error, success, warning } = UseMessageApi();
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [file, setFile] = useState<any>(null);
  const [type, setType] = useState<string>();
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  const onDrop = (acceptedFiles: any) => {
    setFileName(acceptedFiles[0].name);
    setFileSize(acceptedFiles[0].size);
    setType(acceptedFiles[0].type);
    setFile(acceptedFiles[0]);
    setShowProgress(false);
  };

  const { getRootProps, getInputProps, isDragActive }: DropzoneRootProps =
    useDropzone({ onDrop });

  const formatDate = (date: any) => {
    moment.locale("en-ca");
    console.log(date);
    const formattedDate = moment(date).format("MM");

    return formattedDate;
  };

  const handleSubmit = async () => {
    setLoading(true);
    setShowProgress(!showProgress);
    const data = {
      codigo: fileName,
      nome_arquivo: fileName,
      tempo_medio_atualizacao: tempoMedioAtualizacao,
      status_execucao: textStatusExecucao,
      descricao,
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify(data));

    try {
      const response = await axios
        .post("http://localhost:3060/controle-versao/upload", formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = progressEvent.total
              ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
              : 0;
            setProgress(percentCompleted);
            if (percentCompleted >= 100) {
              success("Upload Efetuado com");
              setShowProgress(!showProgress);
              clear();
              setLoading(false);
            }
          },
        })
        .then((response) => {
          setFetch(!fetch);
        });
    } catch (error) {
      console.error(error);
      setShowProgress(!showProgress);
      setLoading(false);
    }
  };

  const clear = () => {
    setFileName("");
    setTempoMedioAtualizacao(0);
    setTextStatusExecucao(String(0));
    setDescricao("");
    setShowProgress(false);
  };
  const setarCamposUpdate = (record: DataType) => {
    setCodigo(record?.codigo);
    setTempoMedioAtualizacao(Number(record?.tempo_medio_atualizacao));
    setTextStatusExecucao(String(record?.status));
    setDescricao(record?.descricao);
    setTextStatusExecucao(
      record?.status_execucao && (record?.status_execucao).toString()
    );
    setNomeArquivo(record?.nome_arquivo);

    console.log(record.nome_arquivo, record.status);
  };

  const showDrawerCriarModal = () => {
    setOpenDrawer(true);
  };
  const onCloseDrawerModal = () => {
    setOpenDrawer(false);
  };
  useEffect(() => {}, []);

  function validarCampos() {
    if (codigo.length > 1) {
      if (descricao.length > 5) {
        if (tempoMedioAtualizacao > 0) {
          if (nomeArquivo.length > 5 || true) {
            if (descricao.length > 5) {
              console.clear();
              handleSubmit();
            } else {
              warning("Campo descrição precisa ter pelo menos 5 caractere.");
            }
          } else {
            warning("Campo Nome do arquivo precisa ser preenchido.");
          }
        } else {
          warning("Campo Tempo médio atualização precisa ser maior que 0.");
        }
      } else {
        warning("Campo descrição precisa ter pelo menos 5 caractere.");
      }
    } else {
      warning("Campo código precisa ter pelo menos 1 caractere.");
    }
  }
  const handleRangeChange = (dates: any) => {
    if (dates) {
      const startDate = dates[0];
      const endDate = dates[1];
      const formattedStartDate = startDate
        ? startDate.format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
      const formattedRange = `${formattedStartDate} - ${formattedEndDate}`;
      //setTextDataAgendamento(formattedRange);
      setTextDataOcorrenciaStart(formattedStartDate);
      setTextDataOcorrenciaFinal(formattedEndDate);
    } else {
      setTextDataOcorrenciaStart("");
      setTextDataOcorrenciaFinal("");
    }
  };

  const handleRangeChangePeriodoDataAtualizacao = (dates: any) => {
    if (dates) {
      const startDate = dates[0];
      const endDate = dates[1];
      const formattedStartDate = startDate
        ? startDate.format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
      //setTextDataAgendamento(formattedRange);
      setTextAtualizacaoDataInicial(formattedStartDate);
      setTextAtualizacaoDataFinal(formattedEndDate);
    } else {
      setTextAtualizacaoDataInicial("");
      setTextAtualizacaoDataFinal("");
    }
  };

  const handleRangeChangePeriodoDataAgendamento = (dates: any) => {
    if (dates) {
      const startDate = dates[0];
      const endDate = dates[1];
      const formattedStartDate = startDate
        ? startDate.format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
      //setTextDataAgendamento(formattedRange);
      setTextAgendamentoDataInicial(formattedStartDate);
      setTextAgendamentoDataFinal(formattedEndDate);
    } else {
      setTextAgendamentoDataInicial("");
      setTextAgendamentoDataFinal("");
    }
  };
  const handleUpload = () => {
    if (file) {
      setCodigo("e");
    }
  };

  if (
    Number(textErroLog) === 0 &&
    Number(textPendenciaConciliacao) === 0 &&
    Number(textRejeicoesFiscais) === 0
  ) {
    setTextDataOcorrenciaStart("");
    setTextDataOcorrenciaFinal("");
  }

  const DrawerModal = (
    <>
      {contextHolder}
      <Drawer
        title="Filtros"
        width={"320px"}
        onClose={onCloseDrawerModal}
        open={openDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            {/* <Button loading={loading} type="primary" onClick={validarCampos}>
              Buscar
            </Button> */}
          </Space>
        }
      >
        <VStack w={"full"} spacing={5}>
          <HStack w={"full"}>
            <VStack alignItems={"start"} w={"full"}>
              <Text>Contrato</Text>
              <Input
                value={textContrato}
                onChange={(e) => {
                  setTextContrato(e.target.value);
                }}
              />
            </VStack>
          </HStack>
          {eMatriz(user?.praca) && (
            <VStack alignItems={"start"} w={"full"}>
              <Text>Praça</Text>
              <Select
                style={{ width: "100%" }}
                value={textPraca}
                onChange={(e) => {
                  setTextPraca(e);
                }}
                placeholder="Por favor insira a praça desejada"
              >
                <Option value="-1">Selecione</Option>
                <Option value="MA000001">PARACATU</Option>
                <Option value="MA000003">UNAI</Option>
                <Option value="MA000004">BRASILIA</Option>
                <Option value="MA000005">BRASILANDIA DE MINAS</Option>
                <Option value="MA000006">JOÃO PINHEIRO</Option>
                <Option value="MA000008">ARAXA</Option>
                <Option value="MA000009">VAZANTE</Option>
                <Option value="MA000013">CRISTALINA</Option>
                <Option value="MA000028">TRÊS MARIAS</Option>
                <Option value="MA000031">MATRIZ</Option>
              </Select>
            </VStack>
          )}
          <VStack alignItems={"start"} w={"full"}>
            <Text>Tipo Instalação</Text>
            <Select
              style={{ width: "100%" }}
              value={textEspecie}
              onChange={(e) => {
                setTextEspecie(e);
              }}
              placeholder="Por favor insira a praça desejada"
            >
              <Option value="">Selecione</Option>
              <Option value="1">Venda</Option>
              <Option value="2">Atualização Gratuita</Option>
              <Option value="3">Alteração</Option>
              <Option value="4">Comodato</Option>
              <Option value="5">Demonstração</Option>
              <Option value="6">Reativação</Option>
            </Select>
          </VStack>
          <VStack alignItems={"start"} w={"full"}>
            <Text>Situação Módulo</Text>
            <Select
              style={{ width: "100%" }}
              value={textSituacaoModulo}
              onChange={(e) => {
                setTextSituacaoModulo(e);
              }}
              placeholder="Por favor insira a praça desejada"
            >
              <Option value="">Selecione</Option>
              <Option value="1">Cliente não cadastrado</Option>
              <Option value="2">Ficha Bloqueada</Option>
              <Option value="3">Manutenção não Cadastrada</Option>
              <Option value="4">Manutenção c/Impedimento</Option>
              <Option value="5">Divergência Valor Manutenção</Option>
            </Select>
          </VStack>
          <VStack alignItems={"start"} w={"full"}>
            <Text>Versão em uso</Text>
            <Input
              value={textVersaoEmUso}
              onChange={(e) => {
                setTextVersaoEmUso(e.target.value);
              }}
            />
          </VStack>
          <VStack alignItems={"start"} w={"full"}>
            <Text>Status de atualização</Text>
            <Select
              style={{ width: "100%" }}
              defaultValue={"-1"}
              onChange={(e) => {
                setTextStatusAtualizacao(e);
              }}
              placeholder="Por favor insira o status de execução"
            >
              <Option value="-1">Selecione</Option>
              <Option value="1">Atualizado</Option>
              <Option value="2">Ag. Atualização</Option>
              <Option value="3">Agu. Intervenção</Option>
              <Option value="4">Desatualizado</Option>
            </Select>
          </VStack>
          <VStack alignItems={"start"} w={"full"}>
            <Text>Status de execução</Text>
            <Select
              style={{ width: "100%" }}
              defaultValue={"-1"}
              onChange={(e) => {
                setTextStatusExecucao(e);
              }}
              placeholder="Por favor insira o status de execução"
            >
              <Option value="-1">Selecione</Option>
              <Option value="2">Automático</Option>
              <Option value="1">Manual</Option>
            </Select>
          </VStack>
          
          <ConfigProvider locale={ptBR}>
     <VStack alignItems={"start"}>
     <Text>Data de Agendamento</Text>
            <RangePicker
              format="DD-MM-YYYY"
              placeholder={["Inicial", "Final"]}
              onChange={handleRangeChangePeriodoDataAgendamento}
            />
     </VStack>
     <VStack alignItems={"start"}>
     <Text>Data da Atualização</Text>
            <RangePicker
              format="DD-MM-YYYY"
              placeholder={["Inicial", "Final"]}
              onChange={handleRangeChangePeriodoDataAtualizacao}
            />
            </VStack>
          </ConfigProvider>
          <VStack alignItems={"start"} w={"full"}>
            <Text>Modelo Documento</Text>
            <Select
              style={{ width: "100%" }}
              defaultValue={"Todos"}
              onChange={(e) => {
                setTextModeloDocumento(e);
              }}
              placeholder="Por favor insira o status de execução"
            >
              <Option value="Todos">Todos</Option>
              <Option value="NFE">NFE</Option>
              <Option value="NFCE">NFCE</Option>
              <Option value="CTE">CTE</Option>
              <Option value="MDFE">MDFE</Option>
            </Select>
          </VStack>

          <FiltroCheckers />
          {(Number(textErroLog) === 1 ||
            Number(textPendenciaConciliacao) === 1 ||
            Number(textRejeicoesFiscais) === 1) && (
            <Stack pl={"30px"}>
              <VStack alignItems={"start"} w={"full"}>
                <Text>Período</Text>
              </VStack>
              <ConfigProvider locale={ptBR}>
                <RangePicker
                  format="DD-MM-YYYY"
                  placeholder={["Data inicial", "Data final"]}
                  onChange={handleRangeChange}
                />
              </ConfigProvider>
            </Stack>
          )}
        </VStack>
      </Drawer>
    </>
  );
  return {
    DrawerModal,
    showDrawerCriarModal,
    contextHolderDrawerCreateVersion: contextHolder,
    textContrato,
  };
};
