import { Button, Center, Tag } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { nomeUsuario } from "../atom/usuario";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { CardsDataContext } from "./principal_page/context/DadosCardContext";

export const User = () => {
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [nameUser, setNameUser] = useRecoilState(nomeUsuario);
  const navigate = useNavigate();
  console.log(nameUser);

  const { user } = useContext(CardsDataContext);
  const [usuario, setUsuario] = useState<any>(null);

  useEffect(() => {
    //getUsuario();
    console.warn("HELLOOO", user);
  }, []);

  return (
    <>
      <Center h={64}>
        Praça:
        <Tag size="sm" mx={2} colorScheme="blue" variant="solid" rounded={3}>
          {user?.praca}
        </Tag>
      </Center>
      <Center h={64}>
        Usuário:
        <Tag size="sm" mx={2} colorScheme="blue" variant="solid" rounded={3}>
          {user?.usuario}
        </Tag>
      </Center>
      <Center h={64}>
        <Button
        isLoading={loadingButton}
          onClick={() => {
            localStorage.removeItem('@App:token')
            setLoadingButton(true)
            setTimeout(() =>{
             
              navigate("/");
              setLoadingButton(false)
              window.location.reload();
            }, 1000)
            
          }}
          size="xs"
          colorScheme="red"
        >
          Sair
        </Button>
      </Center>
    </>
  );
};
