import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const UploadFile = () => {
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [file, setFile] = useState<any>(null);
  const [progress, setProgress] = useState(0);

  const onDrop = (acceptedFiles:any) => {
    setFileName(acceptedFiles[0].name);
    setFileSize(acceptedFiles[0].size);
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('file', file);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'http://localhost:3060/controle-versao/upload');

    xhr.upload.onprogress = (event) => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total);
      setProgress(percentCompleted);
    };

    xhr.onerror = (event) => {
      console.error(event);
    };

    xhr.onload = (event) => {
      console.log(event);
    };

    xhr.send(formData);
  };

  return (
    <>
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Arraste e solte o arquivo aqui...</p>
      ) : (
        <p>Clique aqui ou arraste um arquivo para fazer upload</p>
      )}
    {file &&
    <>
      <input
        type="text"
        placeholder="Nome do arquivo"
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Tamanho do arquivo"
        value={fileSize}
        onChange={(e) => setFileSize(e.target.value)}
      />
    </>}
     
    </div>
    <h1>{progress >= 100 ? 'Completo': `${progress}%`}</h1>
    <button onClick={handleSubmit}>Salvar</button>
    </>
  );
};

export default UploadFile;
