import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import {
  ConfigProvider,
  DatePicker,
  Input,
  Pagination,
  PaginationProps,
  Select,
  Table,
  Typography,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import moment, { Moment } from "moment";
import "moment/locale/pt-br";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDias, getErros } from "../../api/api";

import dayjs, { Dayjs } from "dayjs";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { rowClassName, stringToDateWithHour } from "../../util/util";
import { DiaSemana, Empresa } from "../estatistica/types";
import { criarPeriodo } from "../estatistica/util";
import { UseMessageApi } from "../../message/UseMessageApi";
import ptBR from "antd/es/locale/pt_BR";
const { Text: Text, Title, Link } = Typography;

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;
interface DataTyperas {
  contrato: string;
  id: 18;
  titulo: string;
  detalhe: string;
  versao: string;
  data_ocorrencia: string;
  programa: string;
  os: string;
}
interface DataType {
  contrato: string;
  id: number;
  cod_versao: string;
  dt_ocorrencia: string;
  programa: string;
  desc_simplificada: string;
  dados_erro: string;
  dt_exe: string;
}

export const ErrosLogsListagem = () => {
  const { success, error, contextHolder } = UseMessageApi();
  //const [dataInical, setDataInicial] = useState<Dayjs | null>(
  //  dayjs().subtract(7, "d")
  //);
  // const [dataFinal, setDataFinal] = useState<Dayjs | null>(
  //   dayjs(new Date(), "DD-MM-YYYY")
  // );

  const [loadingErros, setLoadingErros] = useState(false);

  const [erroLog, setErroLog] = useState<DataType[]>([]);
  const [diasSemana, setDiasSemana] = useState<DiaSemana[]>([]);
  const [empresa, setEmpresa] = useState<Empresa>();
  const [filter, setFilter] = useState();

  const [currentPosition, setPosition] = useState<number>(1);
  const [totalItens, setTotalItens] = useState(0);
  const [errosNaSemana, setErrosNaSemana] = useState(0);

  const [limite, setLimite] = useState(5);

  const navigate = useNavigate();

  const { contrato } = useParams();

  const [dataInical, setDataInicial] = useState<Moment | null>(null);
  const [dataFinal, setDataFinal] = useState<Moment | null>(null);

  const [dataInicalExecutavel, setDataInicialExecutavel] = useState<Moment | null>(null);
  const [dataFinalExecutavel, setDataFinalExecutavel] = useState<Moment | null>(null);

  const [codigoVersao, setCodigoVersao] = useState('');
  const [descricao, setDescricao] = useState('');
  const [programa, setPrograma] = useState('');

  const [buscar, setBucar] = useState(false);

  interface DownloadFile {
    fileName: string;
    content: string;
  }

  function downloadTxtFile({ fileName, content }: DownloadFile): void {
    const element = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  const handleDownload = (data: string, contrato:string, errorDado: string) => {
    try {
      const fileToDownload: DownloadFile = {
        fileName: `${data}-${contrato}.txt`,
        content: errorDado,
      };
      downloadTxtFile(fileToDownload);
      success("Gerando arquivo...");
    } catch (e) {
      error("Ocorreu um erro ao gerar o arquivo");
    }
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "Código da Versão",
      dataIndex: "cod_versao",
      key: "cod_versao",
    },
    {
      title: "Descrição Simplificada",
      dataIndex: "desc_simplificada",
      key: "desc_simplificada",
    },
    {
      title: "Data ocorrência",
      dataIndex: "data_ocorrencia",
      key: "data_ocorrencia",
      render: (text, obj: DataType) => {
        return <a>{stringToDateWithHour(obj.dt_ocorrencia)}</a>;
      },
    },
    {
      title: "Data do Executável",
      dataIndex: "dt_exe",
      key: "dt_exe",
      render: (text, obj: DataType) => {
        return <a>{stringToDateWithHour(obj.dt_exe)}</a>;
      },
    },
    {
      title: "Programa",
      dataIndex: "programa",
      key: "programa",
    },
    {
      title: "Ação",
      dataIndex: "açao",
      key: "açao",
      render: (text, obj: DataType) => {
        return (
          <HStack>
            <AiOutlineCloudDownload
              cursor={"pointer"}
              color="#1677FF"
              onClick={() => {
                handleDownload(obj?.dt_ocorrencia, obj?.contrato,obj?.dados_erro); 
              }}
            />
          </HStack>
        );
      },
    },
  ];

  useEffect(() => {
    setLoadingErros(true);
    getErros(currentPosition, limite, dataInical, dataFinal,
      contrato ?? "", dataInicalExecutavel, dataFinalExecutavel,
      codigoVersao, descricao, programa)
      .then((result) => {
        setErroLog(result?.data?.erros);
        setErrosNaSemana(result?.data?.total);
        setTotalItens(result?.data?.total);
        setEmpresa(result?.data?.empresa);
        setLoadingErros(false);
      })
      .then((error) => {
        setLoadingErros(false);
      });
    // getDias(
    //   dataInical,
    //   dataFinal
    // )
    //   .then((result) => {
    //     setDiasSemana(result?.data);
    //     // diasSemana.reverse();

    //     console.warn(result);
    //   })
    //   .then((error) => {});
  }, [currentPosition, limite, dataFinal, dataInical, dataInicalExecutavel, dataFinalExecutavel, codigoVersao, descricao, programa]);

  const next = (numero: number) => {
    setPosition(currentPosition + numero);
  };


  const data = [
    {
      ocorrencia: "Seg",
      uv: 4000,
      dia: 10,
      amt: 2400,
    },
    {
      ocorrencia: "Seg",
      uv: 4000,
      dia: 10,
      amt: 2400,
    },
  ];

  const handleRangeChangePeriodoDataAtualizacao = (dates: any) => {
    //getErrosAll();
    if (dates) {
      const startDate = dates[0];
      const endDate = dates[1];
      const formattedStartDate = startDate
        ? startDate.format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
      //setTextDataAgendamento(formattedRange);
      setDataInicial(formattedStartDate);
      setDataFinal(formattedEndDate);
    } else {
      setDataInicial(null);
      setDataFinal(null);
    }
  };
  const handleRangeChangePeriodoDataExecutavel = (dates: any) => {
    //getErrosAll();
    if (dates) {
      const startDate = dates[0];
      const endDate = dates[1];
      const formattedStartDate = startDate
        ? startDate.format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
      //setTextDataAgendamento(formattedRange);
      setDataInicialExecutavel(formattedStartDate);
      setDataFinalExecutavel(formattedEndDate);
    } else {
      setDataInicialExecutavel(null);
      setDataFinalExecutavel(null);
    }
  };
  function selecionarPeriodo(periodo: string) {
    const periodoCriado = criarPeriodo(Number(periodo));

    setDataInicial(periodoCriado.dataInicial);
    setDataFinal(periodoCriado.dataFinal);
  }
  function stringToDate(date: string) {
    const currentDate = moment(date).format("ll");
    return currentDate;
  }
  const onChange: PaginationProps["onChange"] = (page) => {
    console.log(page);
    setPosition(page);
  };
  const currentDayjs = dayjs();
  const handleFIlter = () => {
    setPosition(1);
  };
  return (
    <VStack w={"100%"}>
      <VStack px={2} py={4} alignItems={"start"} w="full">
        <Title level={3}>
          {empresa?.razao_social} ({empresa?.contrato})
        </Title>
        <SimpleGrid columns={[1, 1, 5]} spacingX={5} spacingY={3}>
          <Box height="80px">
            <FormControl>
              <FormLabel>Data de Ocorrência </FormLabel>
              <VStack w={"full"}>
                <ConfigProvider locale={ptBR}>
                  <RangePicker
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY"
                    placeholder={["Inicial", "Final"]}
                    //value={[currentDayjs, currentDayjs]}
                    onChange={handleRangeChangePeriodoDataAtualizacao}
                  />
                </ConfigProvider>
              </VStack>
            </FormControl>
          </Box>
          <Box height="80px">
            <FormControl>
              <FormLabel>Data do Executável </FormLabel>
              <VStack w={"full"}>
                <ConfigProvider locale={ptBR}>
                  <RangePicker
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY"
                    placeholder={["Inicial", "Final"]}
                    //value={[currentDayjs, currentDayjs]}
                    onChange={handleRangeChangePeriodoDataExecutavel}
                  />
                </ConfigProvider>
              </VStack>
            </FormControl>
          </Box>
          <Box height="80px">
            <FormControl>
              <FormLabel>Código da Versão </FormLabel>
              <Input
                type={"text"}
                value={codigoVersao}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCodigoVersao(e.target.value);
                  handleFIlter();
                }}
                placeholder={"X8"}
              />
            </FormControl>
          </Box>
          <Box height="80px">
            <FormControl>
              <FormLabel>Descrição </FormLabel>
              <Input
                type={"text"}
                value={descricao}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDescricao(e.target.value);
                  handleFIlter();
                }}
                placeholder={"Descrição simplificada"}
              />
            </FormControl>
          </Box>

          <Box height="80px">
            <FormControl>
              <FormLabel>Programa </FormLabel>
              <Input
                type={"text"}
                value={programa}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPrograma(e.target.value);
                  handleFIlter();
                }}
                placeholder={"Ex: PDV"}
              />
            </FormControl>
          </Box>
        </SimpleGrid>
        <HStack></HStack>
      </VStack>
      {/**
 * 
 *       <HStack w={"full"}>
	  <VStack w={"full"}>
	 
            <Select
              style={{ width: "100%" }}
              defaultValue={"30"}
              onChange={(e) => {
                //setStatusExecucao(e);
              }}
              placeholder="Por favor insira o status de execução"
            >
              <Option value="30">Últimos 30 dias</Option>
              <Option value="180">Últimos 180 dias</Option>
			  <Option value="365">Últimos 365 dias</Option>
            </Select>
	  </VStack>
        <Spacer />
        <VStack w={"full"}>
          <ConfigProvider locale={ptBR}>
            <DatePicker
              format={"DD-MM-YYYY"}
              value={dataInical}
              defaultValue={dayjs(new Date(), "DD-MM-YYYY").subtract(7, "d")}
              onChange={(e) => {
                if (e) {
                  setDataInicial(dayjs(e));
                }
              }}
              placeholder="Data inicial"
              style={{ width: "100%" }}
            />
          </ConfigProvider>
        </VStack>
        <VStack w={"full"}>
          <ConfigProvider locale={ptBR}>
            <DatePicker
              format={"DD-MM-YYYY"}
              defaultValue={dayjs(new Date(), "DD-MM-YYYY")}
              onChange={(e) => {
                setDataFinal(dayjs(e));
              }}
              placeholder="Data final"

              style={{
                width: "100%",
              }}
            />
          </ConfigProvider>
        </VStack>
      </HStack>
 */}

      <VStack bgColor={"white"} borderRadius={5} h={400} w={"full"}>
        {/* <VStack w={"full"} h={"40px"} >
					<Select bg={"white"}>
						<option value='option1'>Últimos 7 dias</option>
						<option value='option2'>Últimos 30 dias</option>
						<option value='option3'>Option 3</option>
					</Select>
				</VStack> */}
        {/*erroLog?.length >= 1 ? (*/}
        <>
          {contextHolder}
          <Table
            loading={loadingErros}
            rowClassName={rowClassName}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  //console.log(record?.id, record?.titulo);
                  //http://localhost:3000/painel/colaborador/erro-logs/19
                  //navigate((record?.id).toString());
                },
              };
            }}
            pagination={false}
            size="small"
            style={{ width: "100%", cursor: "pointer" }}
            columns={columns}
            dataSource={erroLog}
          />
          <VStack my={4}>
            <Pagination
              total={totalItens}
              showTotal={(total) => `Total de ${total} itens`}
              defaultPageSize={10}
              defaultCurrent={1}
              onChange={onChange}
              current={currentPosition}
              locale={{ items_per_page: "/ Pagina"}}
            />
          </VStack>
        </>
        ){/**)} */}
      </VStack>
    </VStack>
  );
};
