import { Button, Center, HStack, Spacer, VStack } from "@chakra-ui/react";
import { Spin, Typography } from "antd";
import { AiFillSave } from "react-icons/ai";
import { ContratoProcessoInfo } from "./ContratoProcessoInfo";
import { useEffect, useState } from "react";
import {
  createConfiguracaoPorContrato,
  getConfiguracaoPorContrato,
} from "../../api/api";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  cobrancasConfig,
  dadosCarregadosConfig,
  enviarBoletosConfig,
  enviarConciliacaoConfig,
  enviarErroLogConfig,
  enviarRejeicaoFiscalConfig,
  razaoSocialConfig,
  receberMensagemConfig,
} from "../../atom/formConfiguracaoPorContratoAtom";
import { ControleProcessosBody, ControleProcessosTDO } from "../../types/types";
import { UseMessageApi } from "../../message/UseMessageApi";
import { LoadingOutlined } from "@ant-design/icons";
const { Title, Text } = Typography;
export const ControleProcessoContratoDetalhes = () => {
  const { id } = useParams();
  const [enviarErroLog, setEnviarErroLog] = useRecoilState(enviarErroLogConfig);
  const [enviarRejeicaoFiscal, setEnviarRejeicao] = useRecoilState(
    enviarConciliacaoConfig
  );
  const [enviarConciliacao, setEnviarCOnciliacao] = useRecoilState(
    enviarRejeicaoFiscalConfig
  );
  const [receberMensagem, setReceberMensagem] = useRecoilState(
    receberMensagemConfig
  );
  const [enviarBoletos, setEnviarBoletos] = useRecoilState(enviarBoletosConfig);
  const [enviarCobrancas, setEnviarCobrancas] = useRecoilState(cobrancasConfig);
  const [razaoSocial, setSocialConfig] = useRecoilState(razaoSocialConfig);
  const setDadosCarregados = useSetRecoilState(dadosCarregadosConfig);
  const dadosCarregados = useRecoilValue(dadosCarregadosConfig);
  const { contextHolder, error, success, warning } = UseMessageApi();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getConfiguracaoPorContrato(String(id)).then((result) => {
      setEnviarErroLog(result?.enviar_erro_log);
      setEnviarRejeicao(result?.enviar_rejeicao_fiscal);
      setEnviarCOnciliacao(result?.enviar_conciliacao);
      setReceberMensagem(result?.receber_msgs);
      setEnviarBoletos(result?.enviar_boletos);
      setEnviarCobrancas(result?.cobranca);
      setSocialConfig(result?.razao_social);
      setDadosCarregados(true);
    });
  }, [id, dadosCarregados]);

  const salvar = () => {
    try {
      const body: ControleProcessosBody = {
        contrato: id ?? "",
        baixar: 0,
        enviar_rejeicao_fiscal: enviarRejeicaoFiscal,
        receber_msgs: receberMensagem,
        enviar_boletos: enviarBoletos,
        cobranca: enviarCobrancas,
        enviar_erro_log: enviarErroLog,
        enviar_conciliacao: enviarConciliacao,
      };
      createConfiguracaoPorContrato(body);
      success("Configuração salva");
    } catch (e) {
      error("Ocorreu um erro");
    }
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
if(!dadosCarregados){
  return (
    <Center w="full" h={"85vh"}>
      <VStack>
        <Spin indicator={antIcon} />
        <Text type="secondary">Carregando...</Text>
      </VStack>
    </Center>
  );
}

  return (
    <>
      {contextHolder}
      <VStack w={"100%"}>
        <HStack w="full"></HStack>
        <VStack bgColor={"#F7FAFC"} borderRadius={5} w={"full"}>
          <VStack px={5} py={8} alignItems={"start"} w="full">
            <HStack py={4} w={"full"}>
              <Title level={3}>
                {razaoSocial} ({id})
              </Title>
              <Spacer />
              <Button
                variant={"solid"}
                leftIcon={<AiFillSave />}
                colorScheme="blue"
                isLoading={!dadosCarregados}
                onClick={salvar}
              >
                Salvar
              </Button>
            </HStack>
            <HStack w="full">
              <ContratoProcessoInfo />
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </>
  );
};
