import {
  FolderOpenOutlined,
  HomeOutlined,
  MenuOutlined,
  SettingOutlined,
  FileTextOutlined 
} from "@ant-design/icons";
import {
  Box,
  chakra,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
  useMediaQuery,
  Button as ButtonChakra,
  HStack,
  Center,
  Tag,
} from "@chakra-ui/react";
import { Button, Divider, Layout, Menu, Tooltip } from "antd";
import React, { memo, useEffect, useState } from "react";
import { MdCreateNewFolder, MdUpdate } from "react-icons/md";
import { Outlet, useNavigate } from "react-router-dom";
import LogoMenor from "../assets/logo-icon-48x48.png";
import LogoMaior from "../assets/logonomesuc.f5f52e7a.png";
import { VscNewFolder } from "react-icons/vsc";
import { User } from "../components/User";
import { UserPage } from "../components/UserPage";

const { Header, Sider, Content } = Layout;

const LayoutChakara = chakra(Layout);
const LayoutDefault = () => {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");

  const [fornecedor, setFornecedor] = useState<any>();

  const [url, setUrl] = useState<string>();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toggle = () => {
    setCollapsed(!collapsed);
  };
 
  return (
    <>
      <LayoutChakara h={"100vh"} >
        <Sider
    
          trigger={null}
          style={{ backgroundColor: "#bee3f8"}}
          collapsible
          collapsed={collapsed}
          hidden={!isLargerThan600}
        >
          {collapsed ? (
            <Image className={"logo"} src={LogoMenor} />
          ) : (
            <Image className={"logo"} src={LogoMaior} />
          )}
          <Menu
            theme="light"
            style={{
              backgroundColor: "#bee3f8",
              fontWeight: "600",
              color: "#42413F",
            }}
            mode="inline"
            defaultSelectedKeys={["0"]}
          >
            {/* <Menu.Item onClick={() => { navigate('home/' + url) }} key="1" icon={<BiHomeAlt />}>
							Home
						</Menu.Item> */}
            <Menu.Item
              onClick={() => {
                navigate("colaborador/principal");
              }}
              key="0"
              icon={<HomeOutlined />}
            >
              Home
            </Menu.Item>

            <Menu.Item
              onClick={() => {
                navigate("colaborador/erros");
              }}
              key="1"
              icon={<FileTextOutlined />}
            >
              Erros de Log
            </Menu.Item>
 
            <Menu.Item
              onClick={() => {
                navigate("colaborador/versoes");
              }}
              key="2"
              icon={<VscNewFolder />}
            >
              Versões
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                navigate("colaborador/gerenciamento-atualizacao-clientes");
              }}
              key="3"
              icon={<MdUpdate />}
            >
               <Tooltip title={" Controle de Atualizações"}>

              Atualizações
              </Tooltip>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                navigate("colaborador/controle-processo-contrato");
              }}
              key="4"
              icon={<FolderOpenOutlined />}
            >
                <Tooltip title={"Configuração por Contrato"}>
              Configuração
              </Tooltip>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                navigate("colaborador/parametros");
              }}
              key="5"
              icon={<SettingOutlined />}
            >
              Parâmetros
            </Menu.Item>

            {/*	<Menu.Item onClick={() => { navigate('cotacoes-fechadas') }} key="3" icon={<MailOutlined />}>
						Cotações fechadas
					</Menu.Item> */}
            {/*<Menu.Item key="4" onClick={() => { navigate('relatorios/' + url) }} icon={<FilePdfOutlined />}>
						Relatórios
				</Menu.Item>*/}

            {/* <Menu.Item key="5" onClick={() => { navigate('configuracao/' + url) }} icon={<SettingOutlined />}>
						Configurar usuários
					</Menu.Item> */}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{ padding: 0, backgroundColor: "#bee3f8" }}
          >
            {/*bee3f8 */}
            <Flex>
              {/*

	<Box color={"white"}>
								{React.createElement(collapsed ? MenuOutlined : MenuOutlined, {
									className: 'trigger',
									onClick: isLargerThan600 ? toggle : onOpen,
								})}
							</Box>
 */}

              <Spacer />
              <HStack h={"64px"} mr={5}>
              <UserPage/>
              </HStack>
              <Box>
                {!isLargerThan600 ? (
                  <Box
                    borderRadius={100}
                    padding={1}
                    bg={"white"}
                    marginTop={3}
                    marginRight={2}
                  >
                    <Image width={"30px"} src={LogoMenor} />
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Flex>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              backgroundColor: "#F7FAFC",
              padding: 7,
              minHeight: 280,
              overflow: "auto",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </LayoutChakara>

      <Modal onClose={onClose} size={"full"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{fornecedor?.nome}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Divider />
            <Text>{fornecedor?.email}</Text>
            <Text>{fornecedor?.cnpj}</Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const LayoutPadrao = memo(LayoutDefault);
