import { Box, Center, Text, VStack } from "@chakra-ui/react"
import { Card, Statistic } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
export type CardHomeType = {
    titulo: string;
    valor: number | null;
    color: string;
    error: Error | null | unknown;
    loading: boolean
}
export const CardHome = ({ titulo, valor, color, error, loading }: CardHomeType) => {

    const decimalSeparator = '.'; // Define your desired decimal separator here

    // Custom formatter function to replace the decimal separator
    const formatValue = (value:any) => {
      // Replace the decimal separator with your desired separator
      return value.toString().replace('.', decimalSeparator);
    };
    return (<>
        <Center w="full" >
            <Card style={{ width: '100%' }} bordered={false}>
                {true ? 
                <Statistic  formatter={formatValue} loading={loading} title={titulo} value={valor ?? 0} />
            : <></>}
            </Card>
        </Center>
    </>)
}