import React, { createContext, useState } from 'react';
import { Versao } from '../type';

interface VersaoContextProps {
    versao: Versao,
    setVersao: React.Dispatch<React.SetStateAction<Versao>>;
}
const defautVersao:Versao = {
  versao: '',
  descricao: '',
  nome_arquivo: '',
  codigo: '',
  id: '',
  data_lancamento: '',
  tempo_medio_atualizacao: 0,
  status_execucao: 0,
  status: 0,
  filename_upload: ''
}
export const VersaoContext = createContext<VersaoContextProps>({
    versao: defautVersao,
    setVersao: () => {}
})

const VersaoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [versao, setVersao] = useState<Versao>(defautVersao);
    
    return (
      <VersaoContext.Provider value={{ versao, setVersao }}>
        {children}
      </VersaoContext.Provider>
    );
  };
  export default VersaoProvider;