import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Progress,
  Row,
  Select,
  Select as SelectAnt,
  Space,
  Switch,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { criarVersao, updateSingleVersion } from "../../api/api";
import { UseMessageApi } from "../../message/UseMessageApi";
import "../versoes/versoes.css";
import { DataType } from "./Versoes";
import { HStack, Image, VStack } from "@chakra-ui/react";
import { DropzoneRootProps, useDropzone } from "react-dropzone";
import Upload from "../../img/opcao-de-upload-de-armazenamento-em-nuvem.png";
import Blank from "../../img/blank-page.png";
import { formatBytes } from "../../util/util";
import axios, { HttpStatusCode } from "axios";
import { VersaoContext } from "./context/VersaoContext";
import { DeleteVersion } from "./components/DeleteVersion";
import { VersaoUpdate } from "./type";
const { Text: Text, Title, Link } = Typography;
const { Option } = SelectAnt;

export const useDrawerModalUpdateVersion = (
  setFetch: React.Dispatch<React.SetStateAction<boolean>>,
  fetch: boolean,
  openDrawer: boolean,
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [form] = Form.useForm();

  const [descricao, setDescricao] = useState("");
  const [nomeArquivo, setNomeArquivo] = useState("jjhd.php");
  const [statusExecucao, setStatusExecucao] = useState("0");
  const [codigo, setCodigo] = useState("");
  const [versao, setVersao] = useState("");
  const [todosCamposPreenchidos, setTodosCamposPreenchidos] = useState(false);
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [tempoMedioAtualizacao, setTempoMedioAtualizacao] = useState(0);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  //messages
  const { contextHolder, error, success, warning } = UseMessageApi();
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [file, setFile] = useState<any>(null);
  const [type, setType] = useState<string>();
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  const onDrop = (acceptedFiles: any) => {
    setFileName(acceptedFiles[0].name);
    setFileSize(acceptedFiles[0].size);
    setType(acceptedFiles[0].type);
    setFile(acceptedFiles[0]);
    setShowProgress(false);
  };

  const { getRootProps, getInputProps, isDragActive }: DropzoneRootProps =
    useDropzone({ onDrop });

  const { versao: versaoObject, setVersao: setVersaoObject } =
    useContext(VersaoContext);

  const update = () => {
    console.log(versaoObject);
  };

  
  const handleSubmit = async () => {
    //ainda criar campo de status
    const dados: VersaoUpdate = {
      versao: versao,
      descricao: descricao,
      nome_arquivo: nomeArquivo,
      codigo: versaoObject?.codigo,
      id: versaoObject?.id,
      tempo_medio_atualizacao: Number(tempoMedioAtualizacao),
      status: status ? 1 : 0,
    };
    setLoading(true);
    try {
      updateSingleVersion(Number(versaoObject?.id), dados).then((response) => {
        if (response?.data) {
          if (response?.status === HttpStatusCode.Ok) {
            success("Versão atualizada com sucesso");
            setShowProgress(!showProgress);
            setOpenDrawer(false);
            clear();
            setLoading(false);
            setFetch(!fetch);
          }
        } else {
          error("Ocorreu um erro ao atualizar a versão");
        }
      });
    } catch (error) {
      console.error(error);
      setShowProgress(!showProgress);
      setLoading(false);
    }
  };

  const clear = () => {
    setFileName("");
    setTempoMedioAtualizacao(0);
    setStatusExecucao(String(0));
    setDescricao("");
    setShowProgress(false);
  };
  const setarCamposUpdate = (record: DataType) => {
    setCodigo(record?.codigo);
    setTempoMedioAtualizacao(Number(record?.tempo_medio_atualizacao));
    setStatusExecucao(String(record?.status));
    setDescricao(record?.descricao);
    setStatusExecucao(
      record?.status_execucao && (record?.status_execucao).toString()
    );
    setNomeArquivo(record?.nome_arquivo);

    console.log(record.nome_arquivo, record.status);
  };

  const showDrawerCriarModal = () => {
    setOpenDrawer(true);
  };
  const onCloseDrawerModal = () => {
    setOpenDrawer(false);
  };
  useEffect(() => {
    setFileName(versaoObject?.codigo);
    setTempoMedioAtualizacao(versaoObject?.tempo_medio_atualizacao);
    setStatusExecucao(String(versaoObject?.status_execucao));
    setDescricao(versaoObject?.descricao);
    setStatus(versaoObject?.status === 1 ? true : false)
    
  }, [versaoObject]);

  function validarCampos() {
    if (versaoObject?.codigo .length > 1) {
      if (descricao.length > 5) {
        if (tempoMedioAtualizacao > 0) {
          if (nomeArquivo.length > 5 || true) {
            if (descricao.length > 5) {
              console.clear();
              handleSubmit();
            } else {
              warning("Campo descrição precisa ter pelo menos 5 caractere.");
            }
          } else {
            warning("Campo Nome do arquivo precisa ser preenchido.");
          }
        } else {
          warning("Campo Tempo médio atualização precisa ser maior que 0.");
        }
      } else {
        warning("Campo descrição precisa ter pelo menos 5 caractere.");
      }
    } else {
      warning("Campo código precisa ter pelo menos 1 caractere.");
    }
  }
  const handleUpload = () => {
    if (file) {
      setCodigo("e");
    }
  };
  const DrawerModal = (
    <>
      {contextHolder}
      <Drawer
        title="Atualizar versão"
        width={720}
        onClose={onCloseDrawerModal}
        open={openDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button disabled={loading} onClick={onCloseDrawerModal}>
              Cancelar
            </Button>
            <Button loading={loading} type="primary" onClick={validarCampos}>
              Atualizar
            </Button>
          </Space>
        }
      >
        <VStack w={"full"} spacing={5}>
          <HStack w={"full"}>
            <VStack alignItems={"start"} w={"full"}>
              <Text>Código</Text>
              <Input disabled value={fileName} />
            </VStack>
            <VStack alignItems={"start"} w={"full"}>
              <Text>Tempo Médio atualização</Text>
              <Input
                value={tempoMedioAtualizacao}
                onChange={(e) => {
                  setTempoMedioAtualizacao(Number(e.target.value));
                }}
              />
            </VStack>
          </HStack>
          <HStack w={"full"}>
            <VStack alignItems={"start"} w={"full"}>
              <Text>Status de execução</Text>
              <Select
                style={{ width: "100%" }}
                defaultValue={statusExecucao}
                onChange={(e) => {
                  setStatusExecucao(e);
                }}
                placeholder="Por favor insira o status de execução"
              >
                <Option value="0">Automático</Option>
                <Option value="1">Manual</Option>
              </Select>
            </VStack>
            <VStack alignItems={"start"} w={"full"}>
              <Text>Status da Versão</Text>
              <Switch  checkedChildren="Disponível" unCheckedChildren="Indisponível" onChange={(checked) =>{setStatus(checked)}} checked={status} />
            </VStack>
          </HStack>
          <HStack w={"full"}>
            <VStack alignItems={"start"} w={"full"}>
              <Text>Descrição</Text>
              <Input.TextArea
                value={descricao}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setDescricao(e.target.value);
                }}
                rows={2}
                placeholder="Por favor insira a descrição"
              />
            </VStack>
          </HStack>
          {/*compoenente download aqui */}
        </VStack>
        {/**<DeleteVersion/> */}
      </Drawer>
    </>
  );
  return {
    DrawerModal,
    showDrawerUpdateModal: showDrawerCriarModal,
    contextHolderDrawerCreateVersion: contextHolder,
  };
};
