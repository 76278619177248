  import { atom } from "recoil";

  export const delecaoErrosLog = atom({
      key: 'delecaoErrosLog',
      default: 0,
    });
    export const delecaoPendenciaConciliacao = atom({
      key: 'delecaoPendenciaCOnciliacao',
      default: 0,
    });
    export const delecaoRejeicaoFiscais = atom({
      key: 'delecaoRejeicaoFiscais',
      default: 0,
    });
  