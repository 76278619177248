import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { SearchOutlined } from '@ant-design/icons';
import {
  ConfigProvider,
  DatePicker,
  Input,
  Pagination,
  PaginationProps,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import moment, { Moment } from "moment";
import { FilterOutlined } from "@ant-design/icons";
import "moment/locale/pt-br";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDias, getErros, getErrosAll, getPraca } from "../../api/api";

import dayjs, { Dayjs } from "dayjs";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { reduceText, rowClassName, stringToDateWithHour } from "../../util/util";
import { DiaSemana, Empresa } from "../estatistica/types";
import { criarPeriodo } from "../estatistica/util";
import { UseMessageApi } from "../../message/UseMessageApi";
import ptBR from "antd/es/locale/pt_BR";
import { Estatistica } from "../estatistica/Estatistica";
import { useRecoilState } from "recoil";
import type { ColumnsType, FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';

import {
  atualizacaoDataInicial,
  pracaAtom,
} from "../../atom/formFiltroPrincipal";
import { useDrawerModalErrosLogs } from "../versoes/useDrawerModalErrosLogs";
import { useDrawerModalFiltroErrosAll } from "../principal_page/useDrawerModalFiltroErrosAll";
import {
  codigoVersaoErrosGeraisAtom,
  contratoErrosGeraisAtom,
  dataExecDataFinalErrosGerais,
  dataExecDataInicialErrosGerais,
  descricaoErrosGeraisAtom,
  ocorrenciaDataFinalErrosGerais,
  ocorrenciaDataInicialErrosGerais,
  pracaErrosGeraisAtom,
  programaErrosGeraisAtom,
} from "../../atom/formFiltroErrosLogs";
const { Text: Text, Title, Link } = Typography;

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;
interface DataTyperas {
  contrato: string;
  id: 18;
  titulo: string;
  detalhe: string;
  versao: string;
  data_ocorrencia: string;
  programa: string;
  os: string;
}
interface DataType {
  contrato: string;
  id: number;
  cod_versao: string;
  dt_ocorrencia: string;
  programa: string;
  desc_simplificada: string;
  dados_erro: string;
  dt_exe: string;
  nomecli: string;
}

export const ErrosLogsListagemAll = () => {
  const { success, error, contextHolder } = UseMessageApi();
  //const [dataInical, setDataInicial] = useState<Dayjs | null>(
  //  dayjs().subtract(7, "d")
  //);
  // const [dataFinal, setDataFinal] = useState<Dayjs | null>(
  //   dayjs(new Date(), "DD-MM-YYYY")
  // );

  const [loadingErros, setLoadingErros] = useState(false);

  const [erroLog, setErroLog] = useState<DataType[]>([]);
  const [diasSemana, setDiasSemana] = useState<DiaSemana[]>([]);
  const [empresa, setEmpresa] = useState<Empresa>();
  const [filter, setFilter] = useState();

  const [currentPosition, setPosition] = useState<number>(1);
  const [totalItens, setTotalItens] = useState(0);
  const [errosNaSemana, setErrosNaSemana] = useState(0);

  const [limite, setLimite] = useState(10);

  const navigate = useNavigate();

  const [dataInical, setDataInicial] = useRecoilState(
    ocorrenciaDataFinalErrosGerais
  );
  const [dataFinal, setDataFinal] = useRecoilState(
    ocorrenciaDataInicialErrosGerais
  );

  const [dataInicalExecutavel, setDataInicialExecutavel] = useRecoilState(
    dataExecDataInicialErrosGerais
  );
  const [dataFinalExecutavel, setDataFinalExecutavel] = useRecoilState(
    dataExecDataFinalErrosGerais
  );

  const [textOcorrenciaDataFinal, setTextOcorrenciaDataFinal] = useRecoilState(
    ocorrenciaDataFinalErrosGerais
  );

  // console.clear();
  // console.log(textOcorrenciaDataFinal);

  const [codigoVersao, setCodigoVersao] = useRecoilState(
    codigoVersaoErrosGeraisAtom
  );

  const [descricao, setDescricao] = useRecoilState(descricaoErrosGeraisAtom);
  const [programa, setPrograma] = useRecoilState(programaErrosGeraisAtom);

  const [contratoEmpresa, setContratoEmpresa] = useRecoilState(
    contratoErrosGeraisAtom
  );

  const [textPraca, setTextPraca] = useRecoilState(pracaErrosGeraisAtom);

  const [selectPracaVisible, setSelectPracaVisible] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [atualizarFetch, setAtualizarFetch] = useState(false);
  // const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});

  const [allErros, setAllErros] = useState<DataType[]>([]);

  type ItemType = {
    dt_ocorrencia: string;
    contrato: string;
    dt_exe: string;
    // Adicione outras propriedades, se houver
  };
  
  // Logica de paginação considerando os filtros

  const paginatedData = useMemo(() => {
    let filteredErros = [...allErros];
  
    // Aplicar filtro pelo contrato da empresa
    if (contratoEmpresa.trim() !== '') {
      filteredErros = filteredErros.filter((item) =>
        item?.contrato?.toLowerCase()?.includes(contratoEmpresa?.toLowerCase()) ||
        item?.nomecli?.toLowerCase()?.includes(contratoEmpresa?.toLowerCase())
      );
    }
  
  
    // Ordenar os erros
    const sortedErros = filteredErros.sort((a: ItemType, b: ItemType) => {
      const getDateValue = (item: ItemType): moment.Moment | string | null => {
        if (sortedInfo.columnKey === 'data_ocorrencia') return moment(item.dt_ocorrencia);
        if (sortedInfo.columnKey === 'contrato') return item.contrato;
        if (sortedInfo.columnKey === 'dt_exe') return moment(item.dt_exe);
        return null;
      };
  
      const dateA = getDateValue(a);
      const dateB = getDateValue(b);
  
      if (moment.isMoment(dateA) && moment.isMoment(dateB)) {
        return sortedInfo.order === 'ascend' ? dateA.diff(dateB) : dateB.diff(dateA);
      }
  
      // Se não for Moment, trata como string
      if (typeof dateA === 'string' && typeof dateB === 'string') {
        return sortedInfo.order === 'ascend' ? dateA.localeCompare(dateB) : dateB.localeCompare(dateA);
      }
  
      return 0;
    });
  
    // Atualizar o número total de itens após a filtragem
  const totalFilteredItems = sortedErros.length;
  setTotalItens(totalFilteredItems);

  // Paginar os dados
  const startIndex = (currentPosition - 1) * limite;
  const endIndex = startIndex + limite;
  const paginatedData = sortedErros.slice(startIndex, endIndex);

  return paginatedData;
}, [allErros, currentPosition, limite, contratoEmpresa, sortedInfo]);
  

  // versão simplificada
  // const paginatedData = useMemo(() => { 
  //   let sortedErros = [...allErros];

  //   // faz a ordenação de dt_ocorrencia e contrato
  //   if (sortedInfo.columnKey === 'data_ocorrencia' || 'contrato') {
  //     sortedErros = sortedErros.sort((a, b) => {
  //       const dateA: Moment = moment(a.dt_ocorrencia);
  //       const dateB: Moment = moment(b.dt_ocorrencia);

  //       if (sortedInfo.order === 'ascend') {
  //         return dateA.isBefore(dateB) ? -1 : 1;
  //       } else if (sortedInfo.order === 'descend') {
  //         return dateB.isBefore(dateA) ? -1 : 1;
  //       } else {
  //         return 0;
  //       } 
  //     });
  //   } 
  //   // faz a ordenação de data executavel
  //   if (sortedInfo.columnKey === 'dt_exe' ) {
  //     sortedErros = sortedErros.sort((a, b) => {
  //       const dateA: Moment = moment(a.dt_exe);
  //       const dateB: Moment = moment(b.dt_exe);

  //       if (sortedInfo.order === 'ascend') {
  //         return dateA.isBefore(dateB) ? -1 : 1;
  //       } else if (sortedInfo.order === 'descend') {
  //         return dateB.isBefore(dateA) ? -1 : 1;
  //       } else {
  //         return 0;
  //       } 
  //     })

  //   }

  //   const startIndex = (currentPosition - 1) * limite;
  //   const endIndex = startIndex + limite;

  //   return sortedErros.slice(startIndex, endIndex);
  // }, [allErros, currentPosition, limite, sortedInfo]);

  const {
    contextHolderDrawerCreateVersion,
    showDrawerCriarModal,
    DrawerModal,
  } = useDrawerModalFiltroErrosAll(
    setAtualizarFetch,
    atualizarFetch,
    openDrawer,
    setOpenDrawer
  );

  interface DownloadFile {
    fileName: string;
    content: string;
  }

  function downloadTxtFile({ fileName, content }: DownloadFile): void {
    const element = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  // @ts-ignore
  const handleChange: TableProps<DataType>['onChange'] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<DataType> | SorterResult<DataType>[]) => {
    setSortedInfo(sorter as SorterResult<DataType>);
    setFilteredInfo(filters); // Reconhece a ordenação do filtro
  };
  const handleDownload = (data: string, contrato: string, errorDado: string) => {
    try {
      const fileToDownload: DownloadFile = {
        fileName: `${data}-${contrato}.txt`,
        content: errorDado,
      };
      downloadTxtFile(fileToDownload);
      success("Gerando arquivo...");
    } catch (e) {
      error("Ocorreu um erro ao gerar o arquivo");
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Contrato",
      dataIndex: "contrato",
      className: 'contrato-column',
      key: "contrato",
      width: "100px",
      fixed: "left",
      filteredValue: filteredInfo.contrato || null,
      onFilter: (value, record) => record.toString().includes(value.toString()),
      showSorterTooltip: ({ title: 'Clique para alterar a ordenação' }),
      sorter: (a: DataType, b: DataType) => Number(a.contrato) - Number(b.contrato),
      sortOrder: sortedInfo.columnKey === 'contrato' ? sortedInfo.order : null,
    },
    {
      title: "Razão Social",
      dataIndex: "nomecli",
      key: "nomecli",
      width: "320px",
      fixed: "left",
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => record.toString().includes(value.toString()),
      sorter: (a, b) => a.nomecli.localeCompare(b.nomecli),
      sortOrder: sortedInfo.columnKey === 'nomecli' ? sortedInfo.order : null,
      showSorterTooltip: false,
      render: (text: string) => {
        return (
          <>
            <Tooltip title={text}>
              <a>{reduceText(text, 32)}</a>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Código Da Versão",
      dataIndex: "cod_versao",
      key: "cod_versao",
      width: "150px"
    },
    {
      title: "Descrição Simplificada",
      dataIndex: "desc_simplificada",
      key: "desc_simplificada",
      render: (text: string) => {
        return (
          <>
            <Tooltip title={text}>
              <a>{reduceText(text, 60)}</a>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Data Ocorrência",
      dataIndex: "data_ocorrencia",
      key: "data_ocorrencia",
      width: "150px",
      render: (text, obj: DataType) => {
        return <a>{stringToDateWithHour(obj.dt_ocorrencia)}</a>;
      },
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'data_ocorrencia' ? sortedInfo.order : null,
      showSorterTooltip: ({ title: 'Clique para alterar a ordenação' }),
    },
    {
      title: "Data Do Executável",
      dataIndex: "dt_exe",
      key: "dt_exe",
      width: "120px",
      render: (text, obj: DataType) => {
        return <a>{stringToDateWithHour(obj.dt_exe)}</a>;
      },
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'dt_exe' ? sortedInfo.order : null,
      showSorterTooltip: ({ title: 'Clique para alterar a ordenação' }),

    },
    {
      title: "Programa",
      dataIndex: "programa",
      key: "programa",
    },
    {
      title: "Ação",
      dataIndex: "açao",
      key: "açao",
      fixed: "right",
      width: "100px",
      render: (text, obj: DataType) => {
        return (
          <HStack>
            <AiOutlineCloudDownload
              cursor={"pointer"}
              color="#1677FF"
              onClick={() => {
                handleDownload(obj?.dt_ocorrencia, obj?.contrato, obj?.dados_erro);
              }}
            />
          </HStack>
        );
      },
    },
  ];

  useEffect(() => {
    setLoadingErros(true);

    getPraca().then((result) => {
      if (result?.data?.usuario === "MATRIZ") {
        setSelectPracaVisible(true);
        getErrosAll(
          currentPosition,
          limite,
          dataInical,
          dataFinal,
          dataInicalExecutavel,
          dataFinalExecutavel,
          codigoVersao,
          descricao,
          programa,
          contratoEmpresa,
          textPraca
        )
          .then((result) => {
            setErroLog(result?.data?.erros);
            setErrosNaSemana(result?.data?.total);
            setTotalItens(result?.data?.total);
            setEmpresa(result?.data?.empresa);
            setAllErros(result?.data?.erros || []);
            setLoadingErros(false);
          })
          .then((error) => {
            setLoadingErros(false);
          });
      } else {
        setTextPraca(result?.data?.codigo);
        getErrosAll(
          currentPosition,
          limite,
          dataInical,
          dataFinal,
          dataInicalExecutavel,
          dataFinalExecutavel,
          codigoVersao,
          descricao,
          programa,
          contratoEmpresa,
          result?.data?.codigo
        )
          .then((result) => {
            setErroLog(result?.data?.erros);
            setErrosNaSemana(result?.data?.total);
            setTotalItens(result?.data?.total);
            setEmpresa(result?.data?.empresa);
            setLoadingErros(false);
            setAllErros(result?.data?.erros);
          })
          .then((error) => {
            setLoadingErros(false);
          });
      }
    });
    // getDias(
    //   dataInical,
    //   dataFinal
    // )
    //   .then((result) => {
    //     setDiasSemana(result?.data);
    //     // diasSemana.reverse();

    //     console.warn(result);
    //   })
    //   .then((error) => {});
  }, [
    currentPosition,
    limite,
    dataFinal,
    dataInical,
    dataInicalExecutavel,
    dataFinalExecutavel,
    codigoVersao,
    descricao,
    programa,
    // contratoEmpresa,
    textPraca,
    selectPracaVisible,
    setTextPraca,
  ]);

  // const next = (numero: number) => {
  //   setPosition(currentPosition + numero);
  // };

  // const data = [
  //   {
  //     ocorrencia: "Seg",
  //     uv: 4000,
  //     dia: 10,
  //     amt: 2400,
  //   },
  //   {
  //     ocorrencia: "Seg",
  //     uv: 4000,
  //     dia: 10,
  //     amt: 2400,
  //   },
  // ];

  // const handleRangeChangePeriodoDataAtualizacao = (dates: any) => {
  //   //getErrosAll();
  //   if (dates) {
  //     const startDate = dates[0];
  //     const endDate = dates[1];
  //     const formattedStartDate = startDate
  //       ? startDate.format("YYYY-MM-DD")
  //       : "";

  //     const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
  //     //setTextDataAgendamento(formattedRange);
  //     setDataInicial(formattedStartDate);
  //     setDataFinal(formattedEndDate);
  //   } else {
  //     setDataInicial("");
  //     setDataFinal("");
  //   }
  // };
  // const handleRangeChangePeriodoDataExecutavel = (dates: any) => {
  //   //getErrosAll();
  //   if (dates) {
  //     const startDate = dates[0];
  //     const endDate = dates[1];
  //     const formattedStartDate = startDate
  //       ? startDate.format("YYYY-MM-DD")
  //       : "";
  //     const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
  //     //setTextDataAgendamento(formattedRange);
  //     setDataInicialExecutavel(formattedStartDate);
  //     setDataFinalExecutavel(formattedEndDate);
  //   } else {
  //     setDataInicialExecutavel("");
  //     setDataFinalExecutavel("");
  //   }
  // };

  // function stringToDate(date: string) {
  //   const currentDate = moment(date).format("ll");
  //   return currentDate;
  // }

  const onChange: PaginationProps["onChange"] = (page) => {
    setPosition(page);
  };
  const currentDayjs = dayjs();
  const handleFIlter = () => {
    setPosition(1);
  };
  return (
    <VStack w={"100%"}>
      <VStack px={2} py={4} alignItems={"start"} w="full">
        <Title level={3}>Erros Gerais</Title>
        { }
        <HStack spacing={10}>
          <VStack>
            <Input addonAfter={<SearchOutlined />}
              placeholder="Contrato / Razão Social"
              onChange={(e) => {
                setContratoEmpresa(e.target.value);
                // handleFIlter();
              }}
            />
          </VStack>
          <VStack w={50}>
            <Button
              size={"md"}
              mx={10}
              onClick={showDrawerCriarModal}
              leftIcon={<FilterOutlined />}
              colorScheme={"blue"}
            >
              Filtro
            </Button>
          </VStack>
        </HStack>
        {/* paginatedData entrega os dados filtrados e separados para a geração das estatisticas */}
        {/* <Estatistica erroLog={paginatedData} /> */}
        <HStack></HStack>
      </VStack>
      {/**
 * 
 *       <HStack w={"full"}>
	  <VStack w={"full"}>
	 
            <Select
              style={{ width: "100%" }}
              defaultValue={"30"}
              onChange={(e) => {
                //setStatusExecucao(e);
              }}
              placeholder="Por favor insira o status de execução"
            >
              <Option value="30">Últimos 30 dias</Option>
              <Option value="180">Últimos 180 dias</Option>
			  <Option value="365">Últimos 365 dias</Option>
            </Select>
	  </VStack>
        <Spacer />
        <VStack w={"full"}>
          <ConfigProvider locale={ptBR}>
            <DatePicker
              format={"DD-MM-YYYY"}
              value={dataInical}
              defaultValue={dayjs(new Date(), "DD-MM-YYYY").subtract(7, "d")}
              onChange={(e) => {
                if (e) {
                  setDataInicial(dayjs(e));
                }
              }}
              placeholder="Data inicial"
              style={{ width: "100%" }}
            />
          </ConfigProvider>
        </VStack>
        <VStack w={"full"}>
          <ConfigProvider locale={ptBR}>
            <DatePicker
              format={"DD-MM-YYYY"}
              defaultValue={dayjs(new Date(), "DD-MM-YYYY")}
              onChange={(e) => {
                setDataFinal(dayjs(e));
              }}
              placeholder="Data final"

              style={{
                width: "100%",
              }}
            />
          </ConfigProvider>
        </VStack>
      </HStack>
 */}

      <VStack bgColor={"white"} borderRadius={5} h={400} w={"full"}>
        {/* <VStack w={"full"} h={"40px"} >
					<Select bg={"white"}>
						<option value='option1'>Últimos 7 dias</option>
						<option value='option2'>Últimos 30 dias</option>
						<option value='option3'>Option 3</option>
					</Select>
				</VStack> */}
        {/*erroLog?.length >= 1 ? (*/}
        <>
          {contextHolder}
          <Table
            loading={loadingErros}
            rowClassName={rowClassName}
            // @ts-ignore
            onChange={handleChange}
            scroll={{ x: 1700 }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  //console.log(record?.id, record?.titulo);
                  //http://localhost:3000/painel/colaborador/erro-logs/19
                  //navigate((record?.id).toString());
                },
              };
            }}
            pagination={false}
            size="small"
            style={{ width: "100%", cursor: "pointer" }}
            columns={columns}
            dataSource={paginatedData}
          />
          <VStack my={4}>
            <Pagination
              total={totalItens}
              showTotal={(total) => `Total de ${total} itens`}
              defaultPageSize={limite}
              onShowSizeChange={(current, size) => setLimite(size)}
              defaultCurrent={1}
              onChange={onChange}
              current={currentPosition}
              locale={{ items_per_page: "/ Página"}}
            />
          </VStack>
          {DrawerModal}
        </>
        ){/**)} */}
      </VStack>
    </VStack>
  );
};
