import { atom } from "recoil";

export const contratoAtom = atom({
    key: 'contratoAtom',
    default: '',
  });
  export const versaoEmUsoAtom = atom({
    key: 'versaoEmUsoAtom',
    default: '',
  });
  export const statusAtualizacaoAtom = atom({
    key: 'statusAtualizacaoAtom',
    default: '-1',
  });
  export const pracaAtom = atom({
    key: 'pracaAtom',
    default: 'MA000031',
  });
  export const especieAtom = atom({
    key: 'especieAtom',
    default: '',
  });
  export const situacaoModulo = atom({
    key: 'situacaoModulo',
    default: '',
  });
  export const statusExecucao = atom({
    key: 'statusExecucao',
    default: '-1',
  });
  export const dataAgendamento = atom({
    key: 'dataAgendamento',
    default: '',
  });
  export const dataAtualizacao = atom({
    key: 'dataAtualizacao',
    default: '',
  });
  export const erroLog = atom({
    key: 'erroLog',
    default: '0',
  });
  export const pendenciaConciliacao = atom({
    key: 'pendenciaConciliacao',
    default: '0',
  });
  export const rejeicaoFiscal = atom({
    key: 'rejeicaoFiscal',
    default: '0',
  });
  export const codigoRejeicao = atom({
    key: 'codigoRejeicao',
    default: '',
  });
  export const modeloDocumento = atom({
    key: 'modeloDocumento',
    default: '',
  });
  export const dataOcorrenciaStart = atom({
    key: 'dataOcorrenciaStart',
    default: '',
  });
  export const dataOcorrenciaEnd = atom({
    key: 'dataOcorrenciaEnd',
    default: '',
  });
  export const agendamentoDataInicial = atom({
    key: 'agendamentoDataInicial',
    default: '',
  });
  export const agendamentoDataFinal = atom({
    key: 'agendamentoDataFinal',
    default: '',
  });
  export const atualizacaoDataInicial = atom({
    key: 'atualizacaoDataInicial',
    default: '',
  });
  export const atualizacaoDataFinal = atom({
    key: 'atualizacaoDataFinal',
    default: '',
  });
  export const dataErroLogStart = atom({
    key: 'dataErroLogStart',
    default: '',
  });
  export const dataErroLogEnd = atom({
    key: 'dataErroLogEnd',
    default: '',
  });
  export const dataPendenciaStart = atom({
    key: 'dataPendenciaStart',
    default: '',
  });
  export const dataPendenciaEnd = atom({
    key: 'dataPendenciaEnd',
    default: '',
  });