import { Button, HStack, Spacer, useToast, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Alert,
  DrawerProps,
  Form,
  Input,
  Pagination,
  PaginationProps,
  Select as SelectAnt,
  Table,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { api, getFilesName, getVersoes } from "../../api/api";
import { useRascunho } from "../../hooks/useRascunho";
import "../../styles.css";
import "../../theme/styles.css";
import { UseColumnsVersionApi } from "./ColumnVersoes";
import { Versao } from "./type";
import { useDrawerModalCreateVersion } from "./useDrawerModalCreateVersion";
import { useDrawerModalUpdateVersion } from "./useDrawerModalUpdateVersion";
import { Loading } from "../../loading/Loading";
const { Text: TextAntD, Title, Link } = Typography;
const { Option } = SelectAnt;
const { Search } = Input;
type VersaoSelecionada = {
  codigo: string;
  nomeArquivo: OptionData[];
  tempoMedioAtualizacao: string;
  statusExecucao: string;
  descricao: string;
};

export type DataType = {
  id: number;
  versao: string;
  codigo: string;
  data_lancamento: string;
  nome_arquivo: string;
  tempo_medio_atualizacao: string;
  descricao: string;
  status_execucao: string;
  status: number;
  atualizado_em: string;
};

interface OptionData {
  label: string;
  value: string;
}
interface MyDrawerProps extends DrawerProps {
  initialValues: VersaoSelecionada;
}

function convertOptions(apiOptions: any[]): OptionData[] {
  return apiOptions.map((item: any) => ({
    label: item.originalname,
    value: item.filename,
  }));
}

export const Versoes = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [versoes, setVersoes] = useState<Versao[]>();
  const [limite, setLimite] = useState(5);
  const [currentPosition, setPosition] = useState<number>(1);
  const [totalItens, setTotalItens] = useState(0);
  const toast = useToast();
  const [options, setOptions] = useState<OptionData[]>([]);
  const [loadingVersions, setLoadingVersions] = useState(false);
  const { data: rascunho, mutate } = useRascunho();

  const [atualizarFetch, setAtualizarFetch] = useState(false);
  const [textVersaoNameQuery, setTextVersaoNameQuery] = useState('');
  //drawer
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openUpdateDrawer, setOpenUpdateDrawer] = useState(false);
  const { DrawerModal: DrawerCreateVersion, showDrawerCriarModal } =
    useDrawerModalCreateVersion(
      setAtualizarFetch,
      atualizarFetch,
      openDrawer,
      setOpenDrawer
    );
  const { DrawerModal: DrawerUpdateVersion, showDrawerUpdateModal } =
    useDrawerModalUpdateVersion(
      setAtualizarFetch,
      atualizarFetch,
      openUpdateDrawer,
      setOpenUpdateDrawer
    );
  //column da listagem de versão
  const { columns, contextHolderColumnVersion, setVersao, showUpdateModal } =
    UseColumnsVersionApi(getVersoesBanco, showDrawerUpdateModal);

  const [statusCode, setStatusCode] = useState<number>(0);

  useEffect(() => {
    //setCodigo(rascunho?.data?.originalname);
  }, [rascunho]);

  function getVersions() {
    api
      .get("controle-versao/versionnames")
      .then((response) => {
        const apiOptions = response.data;
        setOptions(apiOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getVersoesBanco() {
    carregar();
  }

  const carregar = async () => {
    setLoadingVersions(true);
    try {
      const response = await getVersoes(currentPosition, limite, textVersaoNameQuery);
      setVersoes(response?.data?.versoes);
      setTotalItens(response?.data?.total);
      setLoadingVersions(false);
    } catch (error) {
      console.log((error as any)?.response?.status);
      setLoadingVersions(false);
      setStatusCode((error as any)?.response?.status);
    }
  };
  useEffect(() => {
    //getVersions();
    getFilesName().then((result) => {
      //setFileNames(result?.data);
    });

    carregar();
  }, [currentPosition, limite, loading, atualizarFetch, textVersaoNameQuery]);

  const updateVersao = () => {};

  const next = (numero: number) => {
    setPosition(currentPosition + numero);
  };

  const onChange: PaginationProps["onChange"] = (page) => {
    console.log(page);
    setPosition(page);
  };
  const rowClassName = (record: any, index: number) => {
    if (index % 2 === 0) {
      return "table-row-light";
    } else {
      return "table-row-dark";
    }
  };
  if (statusCode === 500) {
    return (
      <Alert
        style={{ width: "100%" }}
        message="Error"
        description={"Ocorreu um erro no servidor"}
        type="error"
        showIcon
      />
    );
  }
  if (statusCode === 401) {
    return (
      <Alert
        style={{ width: "100%" }}
        message="Mensagem informativa"
        description={
          "Essa área está dispoível apenas para usuários da praça Matriz."
        }
        type="info"
        showIcon
      />
    );
  }
  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        {contextHolderColumnVersion}
        <VStack w={"full"} bg="#F7FAFC">
          <HStack w="full"></HStack>
          <VStack
            bgColor={"#F7FAFC"}
            height={"70vh"}
            borderRadius={0}
            w={"full"}
          >
            <VStack pr={5}  alignItems={"start"} w="full">
              <Title level={3}>Gerenciamento de Versões</Title>

              <HStack w={"full"}>
              <Input addonAfter={<SearchOutlined />}
                  style={{ width: "300px" }}
                  placeholder="Código da versão"
                  allowClear
                  onChange={(e) => {
                    setTextVersaoNameQuery(e.target.value);
                  }}
                />

                <Spacer />
                <Button
                  variant={"solid"}
                  leftIcon={<PlusOutlined />}
                  colorScheme="blue"
                  onClick={showDrawerCriarModal}
                >
                  Criar Versão
                </Button>
              </HStack>
            </VStack>
            <Table
              loading={loadingVersions}
              size="small"
             
              rowClassName={rowClassName}
              pagination={false}
              style={{ width: "100%" }}
              dataSource={versoes}
              columns={columns}
            />

            <Pagination
              total={totalItens}
              showTotal={(total) => `Total de ${total} itens`}
              defaultPageSize={5}
              defaultCurrent={1}
              onChange={onChange}
              current={currentPosition}
              locale={{ items_per_page: "/ Página"}}
            />
          </VStack>
        </VStack>

        {DrawerCreateVersion}
        {DrawerUpdateVersion}
      </>
    );
  }
};
