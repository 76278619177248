import {
  Alert,
  AlertIcon,
  Button,
  Center,
  Heading,
  HStack,
  Image,
  Input,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios, { AxiosResponse } from "axios";
import { useState } from "react";
import { BiLogInCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { api } from "../api/api";
import Communication from "../images/animation02.gif";
import { api_login } from "../api/api_login";

export const Login = () => {
  const toast = useToast();

  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [loading, setLoading] = useState(false);

  function teste() {
    axios
      .get("templates/JS8PZF-BocYD/data")
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        return error;
      });
  }

  const handleKeyDown = (e:any) => {
    if (e.key === "Enter") {
      if(usuario !== "" && senha !== ""){
        LoginUser();
      }else{
        console.log("not ok")
      }
    }
  };

  const LoginUser = async () => {
    setLoading(true);
    api
      .post("login", { email: usuario, password: senha })
      .then((result: AxiosResponse<any, any>) => {
        if (result?.data?.isAdm) {
          localStorage.setItem("@App:token_adm", result?.data?.acess_token);
          navigate("configuracao-conexao");
          setLoading(false);
          toast({
            title: "Efetuando login adm",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        } else {
          localStorage.setItem("@App:token", result?.data?.acess_token);
          navigate("painel/colaborador/principal");
          setLoading(false);
          toast({
            title: "Efetuando login",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast({
          title: "Erro ao efetuar o login",
          status: "error",
          description: "Credenciais incorretas ou cadastro inativo.",

          duration: 2000,
          isClosable: true,
        });
      });
  };
  const navigate = useNavigate();

  return (
    <VStack bg={"white"}>
      <VStack>
        <Image
          my={5}
          width={"448px"}
          src={
            "https://fiscal.success.inf.br/static/media/logosuc.5b91b2c4.png"
          }
        />
        <VStack w={"full"} pt={3}>
          <Alert status="info" fontWeight={"semibold"}>
            <AlertIcon />
            Portal Atualização automática
          </Alert>
        </VStack>
        <VStack textAlign={"start"} w="full">
          <Heading w="full" my={4} size="md">
            Bem vindo colaborador, digite suas credenciais
          </Heading>
        </VStack>
        <Input
          type={"text"}
          onKeyDown={handleKeyDown}
          onChange={(e: any) => {
            setUsuario(e.target.value);
          }}
          size={"md"}
          placeholder={"Usuário"}
        />
        <Input
         onKeyDown={handleKeyDown}
          type={"password"}
          onChange={(e: any) => {
            setSenha(e.target.value);
          }}
          size={"md"}
          placeholder={"senha"}
        />
        <Button
          isLoading={loading}
          disabled={usuario === "" || senha === "" ? true : false}
          onClick={LoginUser}
          w={"100%"}
          _focus={{}}
          size={"md"}
          colorScheme={"blue"}
        >
          Prosseguir
        </Button>
        {/*
	  <HStack >
		<Text fontSize={"lg"}>Problema ado efetuar login?</Text>
		<Button
		  onClick={() => {
			teste();
		  }}
		  fontSize={"lg"}
		  variant={"link"}
		>
		  Ajuda
		</Button>
	  </HStack>
	*/}
      </VStack>
    </VStack>
  );
};
