import { VStack } from "@chakra-ui/react";
import { Versoes } from "../components/versoes/Versoes";
import VersaoProvider from "../components/versoes/context/VersaoContext";
import { EventosProvider } from "../context/EventosContext";
import { DadosUsuariosVisualizacao } from "../context/UsuariosVisualizacaoContext";

export const VersoesPage = () => {
  return (
    <VStack>
      <EventosProvider>
        <VersaoProvider>
            <DadosUsuariosVisualizacao>
              <Versoes />
            </DadosUsuariosVisualizacao>
        </VersaoProvider>
      </EventosProvider>
    </VStack>
  );
};
