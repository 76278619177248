import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import { ChakraProvider } from '@chakra-ui/react';
import { RecoilRoot } from "recoil"
import { RouteComponent } from './routes/RouteComponent';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/reset.css';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <RecoilRoot>
        <React.StrictMode>
          <RouteComponent />
        </React.StrictMode>
      </RecoilRoot>
    </BrowserRouter>
  </ChakraProvider>

);

reportWebVitals();
